<script>
export default {
    name: "revue_modal",
    data() {
        return {
            message: ''
        }
    },
    methods: {
        onConfirm() {
            this.$emit('confirmed', this.message)
        }
    }
}
</script>

<template>
    <modal name="revue_modal" height="auto" width="520px" :pivotY="0.3" :clickToClose="false">
        <div style="height: 100%">
            <div class="ui attached left aligned segment">
                <h4 class="ui header" style="padding-bottom: 3px;color: #6b7c93;">Attention!</h4>
                <p style="color: #6b7c93; font-size: 15px;"> voulez-vous vraiment activer ce dossier ? </p>
                <div class="ui form">
                    <div class="field">
                        <label style="color: #6b7c93;"> Message </label>
                        <textarea v-model="message" placeholder="Message" rows="3"></textarea>
                    </div>
                </div>
            </div>
            <div class="ui two bottom attached buttons">
                <div class="ui red basic button" @click="$modal.hide('revue_modal')">Annuler</div>
                <div class="ui green basic white button" @click="onConfirm()">Terminer</div>
            </div>
        </div>
    </modal>

</template>

<style scoped>

</style>