<script>
export default {
    name: "revusHistory",
    props: {
        id_dossier: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            historyList: [],
        }
    },
    methods: {
        getHistoryList() {
            const data = {
                collection: "revus_history",
                query: {
                    id_dossier: this.id_dossier
                },
                options: {
                    page: 1,
                    limit: -1
                }
            }
            this.$store.requestFind(data).then((response) => {
                this.historyList = response.docs;
            });
        }
    },
    mounted() {
        this.getHistoryList();
    }
}
</script>

<template>
    <table class="ui fixed very basic tablet stackable table folderTable p-half">
        <thead>
        <tr>
            <th>Opérateur</th>
            <th>Date d'activation</th>
            <th>Message</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in historyList">
            <td class="tableAvatar">
                <img src="@/assets/images/avatar.jpg" alt="">
                <span>{{item.activatedBy.username}}</span>
            </td>

            <td class="text-green">{{$store.getDate(item.activationDate, 'DD/MM/YYYY HH:mm')}}</td>
            <td>
                {{ item.message }}
            </td>
        </tr>

        </tbody>
    </table>
</template>

<style scoped>

</style>