<template>
    <div class="ui accordion" id="vehicule_form">
        <!-- propritere -->
        <div class="tabTitleContainer active title mt-1">
            <div class="tabTitle">Proprietaire</div>
            <div class="line"></div>
            <i class="large angle right icon"></i>
        </div>
        <div class="content active">
            <div class="ui form p-1">

                <div class="three fields">
                    <div class="field">
                        <!--<label class="formLabel">Nom</label>-->
                        <input type="text" placeholder="NOM" class="formInput"
                               v-model="dossier.propri.nom" v-uppercase :disabled="isDisabled">
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">PRENOM</label>-->
                        <input type="text" placeholder="PRENOM" class="formInput"
                               v-model="dossier.propri.prenom" v-uppercase :disabled="isDisabled">
                    </div>

                    <!--<div class="field">
                        <input type="text" class="formInput" placeholder="CIN"
                               v-model="dossier.propri.cin" v-uppercase :disabled="isDisabled"/>
                    </div>-->
                    <div class="field">
                        <!--<label class="formLabel">TEL / FAX</label>-->
                        <input type="text" class="formInput" placeholder="TEL / FAX"
                               v-model="dossier.propri.tel" v-uppercase :disabled="isDisabled"/>
                    </div>
                </div>

                <div class="two fields">

                    <div class="field">
                        <!--<label class="formLabel">EMAIL</label>-->
                        <input type="text" class="formInput" placeholder="EMAIL"
                               v-model="dossier.propri.email" v-uppercase :disabled="isDisabled"/>
                    </div>

                    <div class="field">
                        <!--<textarea class="formInput" placeholder="ADRESSE" rows="2"
                                  v-model="dossier.propri.adresse" v-uppercase :disabled="isDisabled">
                        </textarea>-->
                        <input type="text" class="formInput" placeholder="ADRESSE"
                               v-model="dossier.propri.adresse" v-uppercase :disabled="isDisabled"/>
                    </div>

                </div>

            </div>
        </div>

        <!-- vehicule -->
        <div class="tabTitleContainer title">
            <div class="tabTitle">Vehicule</div>
            <div class="line"></div>
            <i class="large angle right icon"></i>
        </div>
        <div class="content">
            <div class="ui form p-1">
                <div class="three fields">
                    <div class="field">
                        <!--<label class="formLabel">Marque</label>-->
                        <v-select label="label" :options="marque_list" placeholder="MARQUE"
                                  v-model="dossier.vehicule.marque"
                                  class="formInput" @input="getModels" :disabled="isDisabled">
                        </v-select>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">Modèle</label>-->
                        <v-select label="label" :options="model_list" v-model="dossier.vehicule.model"
                                  class="formInput" placeholder="MODÈLE"
                                  :disabled="!dossier.vehicule.marque || isDisabled">
                        </v-select>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">Type</label>-->
                        <input type="text" class="formInput" placeholder="TYPE"
                               :value="dossier.vehicule.model ? dossier.vehicule.model.type_model : '' " disabled
                               v-uppercase/>
                    </div>

                </div>

                <div class="three fields">
                    <div class="field">
                        <!--<label class="formLabel">N° immat</label>-->
                        <div class="ui action input">
                            <input type="text" class="formInput" placeholder="N° IMMAT"
                                   v-model.trim="dossier.vehicule.n_immat" v-uppercase :disabled="isDisabled"/>
                            <button class="ui icon button immat-popup" @click="getDossierByImmat">
                                <i class="search icon"></i>
                            </button>
                            <div class="ui flowing popup top left transition hidden">
                                <div class="ui column divided center aligned grid">
                                    <div class="column">
                                        <p v-if="!dossier_by_immat.length">Aucun dossier trouvé</p>
                                        <p v-else v-for="dossier_immat in dossier_by_immat" style="color: var(--main-color-red);">{{dossier_immat.numero}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="field" style="position: relative">
                        <!--<label class="formLabel">Immat-WW</label>-->
                        <input type="text" id="immat-ww" style="padding-left: 50px" class="formInput"
                               v-model.trim="dossier.vehicule.immat_ww" v-uppercase :disabled="isDisabled" placeholder="IMMAT-WW"/>
                        <label for="immat-ww" class="static-value">WW-</label>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">N° serie</label>-->
                        <input type="text" class="formInput" placeholder="N° serie"
                               v-model="dossier.vehicule.n_serie" v-uppercase :disabled="isDisabled"/>
                    </div>
                </div>

                <div class="three fields">
                    <div class="field">
                        <!--<label class="formLabel">KILOMETRAGE</label>-->
                        <input type="text" class="formInput" placeholder="KILOMETRAGE"
                               v-model="dossier.vehicule.kilometrage" v-uppercase :disabled="isDisabled"/>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">ENERGIE</label>-->
                        <v-select class="formInput" style="text-transform: uppercase" placeholder="ENERGIE"
                                  v-model="dossier.vehicule.energie" :options="energie_list" :disabled="isDisabled">
                        </v-select>
                    </div>
                    <div class="field">
                        <!--<label class="formLabel">PUISSANCE</label>-->
                        <input type="text" class="formInput" placeholder="PUISSANCE"
                               v-model="dossier.vehicule.puissance" v-uppercase :disabled="isDisabled"/>
                    </div>
                </div>

                <div class="three fields">
                    <div class="field">
                        <!--<label class="formLabel">ETAT GENERAL</label>-->
                        <!--<input type="text" class="formInput" placeholder="ETAT GENERAL" v-model="dossier.vehicule.etat_general"/>-->
                        <v-select class="formInput" placeholder="ETAT GENERAL"
                                  :options="['B', 'M', 'TB']" v-model="dossier.vehicule.etat_general"
                                  :disabled="isDisabled">
                        </v-select>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">ETAT DES PNEUS</label>-->
                        <!--<input type="text" class="formInput"  placeholder="ETAT DES PNEUS" v-model="dossier.vehicule.etat_des_pneus"/>-->
                        <v-select class="formInput" placeholder="ETAT DES PNEUS"
                                  :options="['B', 'M', 'TB']" v-model="dossier.vehicule.etat_des_pneus"
                                  :disabled="isDisabled">
                        </v-select>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">DMC</label>-->
                        <date-milli class="formInput" v-model="dossier.vehicule.dmc" :disabled="isDisabled"
                                    placeholder="DMC" :showPlaceholder="true"/>
                    </div>
                </div>
                <div class="field">
                    <!--<label class="formLabel">Lieu de dépot du véhicule</label>-->
                    <input type="text" class="formInput" placeholder="LIEU DE DÉPOT DU VÉHICULE"
                           v-model="dossier.vehicule.depot_vehicule" v-uppercase :disabled="isDisabled"/>
                </div>


                <!--<div class="three fields">
                    <div class="field">
                        <label class="formLabel">CONSTAT AV</label>
                        <input type="text" class="formInput" placeholder="CONSTAT AV" v-model="dossier.vehicule.constat_av"/>
                    </div>
                    <div class="field">
                        <label class="formLabel">CONSTAT EN</label>
                        <input type="text" class="formInput" placeholder="CONSTAT EN" v-model="dossier.vehicule.constat_en"/>
                    </div>
                    <div class="field">
                        <label class="formLabel">CONSTAT AP </label>
                        <input type="text" class="formInput" placeholder="CONSTAT AP" v-model="dossier.vehicule.constat_ap"/>
                    </div>
                </div>-->

            </div>
        </div>

        <div class="tabTitleContainer title">
            <div class="tabTitle">Points de choc</div>
            <div class="line"></div>
            <i class="large angle right icon"></i>
        </div>
        <div class="content">
            <div class="carblock">
                <h4 class="check-on-image " style="left:10px; top:20px;">Face:</h4>

                <div class="check-on-image" style="left: -45px;top: 69px;">
                    <label for="pare_brise_avant" class="car-checkbox">Pare Brise Avant </label>
                    <input id="pare_brise_avant" type="checkbox" :value="{label:'Pare Brise Avant'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 18px;top: 140px;">
                    <label for="face_avd" class="car-checkbox">AVD</label>
                    <input id="face_avd" type="checkbox" :value="{label:'Face AVD'}" v-model="dossier.points_chock"
                           :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 50px;top: 185px;">
                    <label for="face_avant" class="car-checkbox">Face Avant </label>
                    <input id="face_avant" type="checkbox" :value="{label:'Face Avant'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 150px;top: 140px;">
                    <input id="face_avg" type="checkbox" :value="{label:'Face AVG'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                    <label for="face_avg" class="car-checkbox">AVG</label>
                </div>

                <div class="check-on-image" style="right: 93px;top: 70px;">
                    <label for="lunette_arriere" class="car-checkbox">Lunette arrière</label>
                    <input id="lunette_arriere" type="checkbox" :value="{label:'Lunette arrière'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 280px;top: 140px;">
                    <label for="face_arg" class="car-checkbox">ARG</label>
                    <input id="face_arg" type="checkbox" :value="{label:'Face ARG'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 310px;top: 180px;">
                    <label for="face_arriere" class="car-checkbox">Face Arriere </label>
                    <input id="face_arriere" type="checkbox" :value="{label:'Face Arriere'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 420px;top: 140px;">
                    <input id="face_ard" type="checkbox" :value="{label:'Face ARD'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                    <label for="face_ard" class="car-checkbox">ARD</label>
                </div>


                <h4 class="check-on-image " style="left:10px; top:190px; ">Latéral:</h4>

                <div class="check-on-image" style="right: 103px;top: 256px;">
                    <input id="vitre_porte_avd" type="checkbox" :value="{label:'Vitre porte AVD'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                    <label for="vitre_porte_avd" class="car-checkbox">Vitre porte AVD</label>
                </div>
                <div class="check-on-image" style="left: 35px;top: 255px;">
                    <label for="vitre_porte_ard" class="car-checkbox">Vitre porte ARD</label>
                    <input id="vitre_porte_ard" type="checkbox" :value="{label:'Vitre porte ARD'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 10px;top: 320px;">
                    <label for="lateral_ard" class="car-checkbox">ARD</label>
                    <input id="lateral_ard" type="checkbox" :value="{label:'Latéral ARD'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 160px;top: 320px;">
                    <label for="lateral_droite" class="car-checkbox">Latéral droite</label>
                    <input id="lateral_droite" type="checkbox" :value="{label:'Latéral Droite'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 410px;top: 320px;">
                    <input id="lateral_avd" type="checkbox" :value="{label:'Latéral AVD'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                    <label for="lateral_avd" class="car-checkbox">AVD</label>
                </div>


                <div class="check-on-image" style="left: 103px;bottom: 144px;">
                    <label for="vitre_porte_avg" class="car-checkbox">Vitre porte AVG</label>
                    <input id="vitre_porte_avg" type="checkbox" :value="{label:'Vitre porte AVG'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="right: 45px;bottom: 144px;">
                    <input id="vitre_porte_arg" type="checkbox" :value="{label:'Vitre porte ARG'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                    <label for="vitre_porte_arg" class="car-checkbox">Vitre porte ARG</label>
                </div>
                <div class="check-on-image" style="left: 10px;top: 540px;">
                    <label for="lateral_avg" class="car-checkbox">AVG</label>
                    <input id="lateral_avg" type="checkbox" :value="{label:'Latéral AVG'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 160px;bottom: 80px;">
                    <label for="lateral_gauche" class="car-checkbox">Latéral gauche</label>
                    <input id="lateral_gauche" type="checkbox" :value="{label:'Latéral Gauche'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                </div>
                <div class="check-on-image" style="left: 410px;top: 540px;">
                    <input id="lateral_arg" type="checkbox" :value="{label:'Latéral ARG'}"
                           v-model="dossier.points_chock" :disabled="isDisabled">
                    <label for="lateral_arg" class="car-checkbox">ARG</label>
                </div>
                <img src="@/assets/images/car.png" alt="point_choque s">
            </div>
        </div>

    </div>
</template>

<script>
    import dateMilli from "@/components/dateMilli.vue";

    export default {
        name: "assure",
        components: {dateMilli},
        props: {
            dossier_prop: {
                type: Object,
                default() {
                    return {}
                }
            },
            marque_list_prop: {
                type: Array,
                default: function () {
                    return []
                }
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                dossier: this.dossier_prop,
                energie_list: ['DIESEL', 'ELECTRIQUE', 'ESSENCE'],
                marque_list: [],
                model_list: [],
                assurance_list: [],
                dossier_by_immat: []
            }
        },
        methods: {
            getModels() {
                log('getModels');
                let data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {type: 'model'},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };

                if (this.dossier.vehicule.marque && this.dossier.vehicule.marque._id) {
                    data.query.id_pere = this.dossier.vehicule.marque._id
                }

                this.$store.requestFind(data).then(ar => {
                    this.model_list = ar.docs;
                }, code => {
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});
                })
            },
            getDossierByImmat(){
                if(!this.dossier.vehicule.n_immat){
                    this.dossier_by_immat = [];
                    return;
                }
                const data = {
                    collection: this.$SHARED.collections.documents,
                    /*query: {
                        "vehicule.n_immat": this.dossier.vehicule.n_immat,
                    },*/
                    query: {
                        $or: [
                            {"vehicule.n_immat": this.dossier.vehicule.n_immat},
                            {"adverse.vehicule.n_immat": this.dossier.vehicule.n_immat},
                            {"adverse_second.vehicule.n_immat": this.dossier.vehicule.n_immat}
                        ]
                    },
                    options: {
                        page: 1,
                        limit: -1
                    }
                };
                if(this.dossier.hasOwnProperty("_id")){
                    data.query._id = {$ne: this.dossier._id}
                }

                this.$store.requestFind(data).then(ar => {
                    this.dossier_by_immat = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            }
        },
        mounted() {
            this.marque_list = JSON.parse(JSON.stringify(this.marque_list_prop));
            this.getModels();
            $('.immat-popup').popup({on: 'click',inline: false});
        }
    }
</script>

<style scoped>
    .ui.accordion .active.title .angle.right,
    .ui.accordion .accordion .active.title .angle.right {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .ui.accordion.menu .item .active.title > .angle.right {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .check-on-image {
        position: absolute;
        z-index: 2;
    }

    .carblock {
        position: relative;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .static-value {
        position: absolute;
        left: 20px;
        font-weight: bold !important;
        font-size: 1em !important;
        color: var(--main-color-blue) !important;
        top: 10px;
    }
    .immat-popup{
        background-color: #E4F0F4;
        border: 1px solid rgba(13, 68, 132, 0.25)
    }
</style>