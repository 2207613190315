<template>
    <div class="position-relative imagePreviewContainer mt-1" style="justify-content: center">
        <div v-if="!image_src.length" class="default-image p-half">
            <img height="150px" src="@/assets/images/default.png" />
            <h3 class="m-0 normal-text"> Pas d'image disponible actuellement </h3>
        </div>

        <div class="ui tiny images flex" style="flex-wrap: wrap;" v-else>
            <div v-for="image in filteredAttachments.images" style="position: relative;flex: 0 0 auto;" >
                <img class="ui bordered rounded image imagePreview cursor-pointer"
                      :src="server_address + image.path" @click="showImage(image)" alt/>


                <div class="ui radio checkbox radio-on-image" v-if="selectedAction === 'default_image'">
                    <input type="radio" :value="image._id"
                            name="default" :checked="image.is_default"
                           @change="changedDefault(image._id)"/>
                    <label></label>
                </div>

                <div class="ui checkbox radio-on-image" v-if="selectedAction === 'delete_image'">
                    <input type="checkbox" :value="{_id: image._id, path: image.path}" v-model="checkedDeleteImages"/>
                    <label></label>
                </div>


            </div>
        </div>

        <div class="ui grid" style="margin: auto;">
            <div v-for="file in filteredAttachments.pdf" class="column default-image pdf-file" style="width: 46.4%!important">
                <img class="ui image imagePreview" src="@/assets/images/pdf-file.png" alt @click="downloadFile(file)" />
                <h4> {{file.name}} </h4>

                <div class="ui checkbox radio-on-image" v-if="selectedAction === 'delete_image'">
                    <input type="checkbox" :value="{_id: file._id, path: file.path}" v-model="checkedDeleteImages"/>
                    <label></label>
                </div>
            </div>

        </div>

        <div class="ui right floated button success-button default-button" :class="{'disabled' : !current_default}"
             v-if="image_src.length && selectedAction === 'default_image'"
             @click="updateDefault">
            Définir défaut
        </div>

        <div class="ui right floated button danger-button default-button" @click="deleteAttachmentsConfirmation"
             v-if="image_src.length && selectedAction === 'delete_image'"
             :class="{'disabled' : !checkedDeleteImages.length || isBusy}">
            Supprimer les images sélectionnées
        </div>

        <div style="clear: both"></div>

    </div>
</template>

<script>
export default {
    name:'image-tab',
    props:{
        image_src: '',
        selectedAction: String
    },
    data(){
        return {
            current_default:null,
            checkedDeleteImages: [],
            isBusy: false
        }
    },
    methods:{
        downloadFile(file){
            let file_path = this.server_address + file.path;
            let a = document.createElement('a');
            a.href = file_path;
            a.setAttribute('download', file_path);
            a.setAttribute('target', '_blanc');
            a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        changedDefault(_id){
            log("changedDefault");
            this.current_default = _id;
        },
        updateDefault(){
            log("selectDefault");
            if(!this.current_default)
                return;

            let ids_to_update = _.map(this.filteredAttachments.images, elm => elm._id);
            const data = {
                ids_to_update:ids_to_update,
                default_id: this.current_default,
            };
            console.log(data);
            this.$store.dispatchAsync(this.$SHARED.services.attachment.update_default, data).then(() => {
                this.$emit('updateCompleted');
                this.$notify({group: 'user-message', type: 'success', text: this.$SHARED.messages.document.update_succeeded});
            }, code => {
                this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});
            });
        },
        showImage(image){
            let url = this.$router.resolve({
                name: 'imageViewer',
                params: {
                    image_src: image.path,
                    image_type: image.type,
                    image_id_doc: image.id_doc
                }
            })
            window.open(url.href, "", 'location=yes,height=570,width=1136,scrollbars=yes')
        },
        deleteAttachments(){
            console.log("deleteAttachments")

            if (this.isBusy) return;

            this.isBusy = true

            const data = {
                attachmentToDelete: this.checkedDeleteImages
            }

            this.$store.dispatchAsync(this.$SHARED.services.attachment.remove, data).then(() => {
                this.$notify({group: 'user-message', type: 'success', text: this.$SHARED.messages.document.update_succeeded});
                this.$emit("updateCompleted")
                this.checkedDeleteImages = []
            }, code => {
                this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});

            }).finally(() => {
                this.isBusy = false
            })
        },
        deleteAttachmentsConfirmation(){
            this.$modal.show('dialog', {
                title: 'Attention!',
                text: 'Vous voulez vraiement supprimer ces images ?',
                buttons: [
                    {
                        title: 'Non',
                        handler: () => this.$modal.hide('dialog')
                    },
                    {
                        title: 'Oui',
                        handler: () => {
                            this.deleteAttachments()
                            this.$modal.hide('dialog')
                        }

                    }
                ]
            })
        }
    },
    computed:{
        filteredAttachments(){
            let pdf = [];
            let images = [];
            this.image_src.forEach((attachment)=>{
                attachment.file_type === 'pdf' ? pdf.push(attachment) : images.push(attachment);
            });
            return {pdf, images}
        },
        server_address(){
            return this.$store.state.SERVER_ADDRESS + '/';
        }
    }
};
</script>


<style scoped>
    .cursor-pointer{
        cursor: pointer;
    }
    .default-image{
        padding: 18px 20px 22px;
        background: #f5f5f5;
        text-align: center;
    }
    .default-image h3{
        color: var(--main-color-blue);
    }
    .pdf-file{
        max-height: 225px;
        width: 46.4%!important;
        margin: 0 0.5rem 0.5rem 0 !important
    }
    .pdf-file img{
        max-height: 150px;
        max-width: 50%;
        margin: auto;
        cursor: pointer;
    }
    .pdf-file h4{
        margin-top: 10px
    }
    .radio-on-image{
            position: absolute;
            z-index: 2;
            left: 10px;
            top: 2px;
    }
    .imagePreview{
        object-fit: cover;
        height: 88px !important;
    }
    .imagePreviewContainer{
        /* height: 33.8em; */
        overflow-x: hidden;
        overflow-y: auto;
    }
    .default-button{
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        margin-right: 30px !important;
    }
</style>