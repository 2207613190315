<template>
    <modal name="dynamic-modal" height="auto" width="520px" :pivotY="0.3" :clickToClose="false">
        <div style="height: 100%">
            <div class="ui attached left aligned segment">
                <h4 class="ui header" style="padding-bottom: 3px;color: #6b7c93;">Attention!</h4>
                <p style="color: #6b7c93; font-size: 15px;"> {{message}} </p>
            </div>
            <div class="ui two bottom attached buttons">
                <div class="ui red basic button" @click="$modal.hide('dynamic-modal')">Non</div>
                <div class="ui green basic white button" :class="{isBusy: 'disabled'}" @click="confirm">Oui</div>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: "dynamic_modal",
        props:{
            message: {
                type: String,
                default: ""
            },
            isBusy:{
                type: Boolean,
                default: false
            }
        },
        methods:{
            confirm(){
                if(this.isBusy) return;

                this.$emit('actionConfirmed');
            }
        }
    }
</script>

<style scoped>

</style>