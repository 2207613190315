<template>
    <table class="ui fixed very basic tablet stackable table folderTable p-half">
        <thead>
        <tr>
            <th>Type</th>
            <th>Operateur</th>
            <th>Date de génération</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="file in generated_files">
            <td>{{ file.sous_type}}</td>
            <td class="tableAvatar"> <img src="@/assets/images/avatar.jpg" alt="">
                <span>{{file.username}}</span>
            </td>

            <td class="text-green">{{$store.getDate(file.date_creation, 'DD/MM/YYYY HH:mm')}}</td>
            <td>
                <i class=" download icon" style="cursor: pointer" @click="downloadFile(file)" title="Télécharger"></i>
                <i class="disabled trash icon"></i>
            </td>
        </tr>

        </tbody>
    </table>
</template>

<script>
    export default {
        name: "generated_files_table",
        props: {
            attachment_prop: {type: Array},
            filterBy: {
                type: Object,
                default: function () {
                    return {type: 'generated'}
                }
            }
        },
        methods:{
            downloadFile(file) {
                let file_path = this.$store.state.SERVER_ADDRESS + '/' + file.link;
                let a = document.createElement('a');
                a.href = file_path;
                a.setAttribute('download', file_path);
                a.setAttribute('target', '_blanc');
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            },
        },
        computed:{
            generated_files(){
                return _.filter(this.attachment_prop, this.filterBy)
            }
        }
    }
</script>

<style scoped>

</style>