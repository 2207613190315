<template>
    <div class="ui accordion" id="Adverse_form">
        <!-- Adverse -->
        <div class="tabTitleContainer active title mt-1">
            <div class="tabTitle">Adversaire</div>
            <div class="line"></div>
            <i class="large angle right icon"></i>
        </div>
        <div class="content active">
            <div class="ui form p-1">
                <div class="four fields">
                    <div class="field">
                        <!--<label class="formLabel">Nom</label>-->
                        <input type="text" placeholder="NOM" class="formInput"
                               v-model="adverse.nom" v-uppercase :disabled="isDisabled">
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">Prenom</label>-->
                        <input type="text" placeholder="PRENOM" class="formInput"
                               v-model="adverse.prenom" v-uppercase :disabled="isDisabled">
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">TEL / FAX</label>-->
                        <input type="text" class="formInput" placeholder="TEL / FAX" v-model="adverse.tel"
                               :disabled="isDisabled"/>
                    </div>
                    <div class="field">
                        <!--<label class="formLabel">EMAIL</label>-->
                        <input type="text" class="formInput" placeholder="EMAIL" v-model="adverse.email" v-uppercase
                               :disabled="isDisabled"/>
                    </div>
                </div>

                <div class="three fields">
                    <div class="field">
                        <!--<label class="formLabel">Assurance</label>-->
                        <v-select label="label" :options="assurance_list" class="formInput" placeholder="ASSURANCE"
                                  v-model="adverse.assurance" @input="getCourtierList"
                                  :disabled="isDisabled || (type_reference === 'croise' && !!id_doc)">
                        </v-select>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">Police</label>-->
                        <input type="text" class="formInput" placeholder="POLICE"
                               v-model="adverse.vehicule.police" v-uppercase :disabled="isDisabled"/>
                    </div>
                    <div class="field">
                        <!--<label class="formLabel">ADRESSE</label>-->
                        <input type="text" class="formInput" placeholder="ADRESSE" v-model="adverse.adresse" v-uppercase
                               :disabled="isDisabled"/>
                    </div>
                </div>

                <div class="field">
                    <!--<label class="formLabel">Agent/Courtier</label>-->
                    <v-select label="label" :options="courtier_list" class="formInput" placeholder="AGENT/COURTIER"
                              v-model="adverse.courtier" :disabled="!adverse.assurance || isDisabled">
                    </v-select>
                </div>

            </div>
        </div>
        <!-- vehicule adverse -->
        <div class="tabTitleContainer title">
            <div class="tabTitle">Vehicule d'adversaire</div>
            <div class="line"></div>
            <i class="large angle right icon"></i>
        </div>
        <div class="content">
            <div class="ui form p-1">
                <div class="two fields">
                    <div class="field">
                        <!--<label class="formLabel">Marque</label>-->
                        <v-select label="label" :options="marque_list" placeholder="MARQUE"
                                  v-model="adverse.vehicule.marque"
                                  class="formInput" @input="getModels" :disabled="isDisabled">
                        </v-select>
                    </div>
                    <div class="field">
                        <!--<label class="formLabel">Modèle</label>-->
                        <v-select label="label" :options="model_list" v-model="adverse.vehicule.model"
                                  class="formInput" placeholder="MODÈLE"
                                  :disabled="!adverse.vehicule.marque || isDisabled">
                        </v-select>
                    </div>
                    <div class="field">
                        <!--<label class="formLabel">Type</label>-->
                        <input type="text" class="formInput" placeholder="TYPE"
                               :value="adverse.vehicule.model ? adverse.vehicule.model.type_model : '' " disabled
                               v-uppercase/>
                    </div>
                </div>

                <div class="three fields">

                    <div class="field">

                        <div class="ui action input">
                            <input type="text" class="formInput" placeholder="N° immat"
                                   v-model.trim="adverse.vehicule.n_immat" v-uppercase :disabled="isDisabled"/>

                            <button class="ui icon button immat-popup" @click="getDossierByImmat">
                                <i class="search icon"></i>
                            </button>
                            <div class="ui flowing popup top left transition hidden">
                                <div class="ui column divided center aligned grid">
                                    <div class="column">
                                        <p v-if="!dossier_by_immat.length">Aucun dossier trouvé</p>
                                        <p v-else v-for="dossier_immat in dossier_by_immat" style="color: var(--main-color-red);">
                                            {{dossier_immat.numero}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="field" style="position: relative">
                        <!--<label class="formLabel">Immat-WW</label>-->
                        <input type="text" id="immat-ww-adverse" style="padding-left: 51px" class="formInput"
                               v-model="adverse.vehicule.immat_ww" v-uppercase :disabled="isDisabled" placeholder="IMMAT-WW"/>
                        <label for="immat-ww-adverse" class="static-value">WW-</label>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">N° serie</label>-->
                        <input type="text" class="formInput" placeholder="N° SERIE"
                               v-model="adverse.vehicule.n_serie" v-uppercase :disabled="isDisabled"/>
                    </div>
                </div>

                <div class="three fields">
                    <div class="field">
                        <!--<label class="formLabel">KILOMETRAGE</label>-->
                        <input type="text" class="formInput" placeholder="KILOMETRAGE"
                               v-model="adverse.vehicule.kilometrage" v-uppercase :disabled="isDisabled"/>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">ENERGIE</label>-->
                        <v-select class="formInput" style="text-transform: uppercase" placeholder="ENERGIE"
                                  v-model="adverse.vehicule.energie" :options="energie_list" :disabled="isDisabled">
                        </v-select>
                    </div>
                    <div class="field">
                        <!--<label class="formLabel">PUISSANCE</label>-->
                        <input type="text" class="formInput" placeholder="PUISSANCE"
                               v-model="adverse.vehicule.puissance" v-uppercase :disabled="isDisabled"/>
                    </div>
                </div>

                <div class="two fields">
                    <div class="field">
                        <!--<label class="formLabel">ETAT GENERAL</label>-->
                        <!--<input type="text" class="formInput" placeholder="ETAT GENERAL" v-model="vehicule.etat_general"/>-->
                        <v-select class="formInput" placeholder="ETAT GENERAL" style="text-transform: uppercase"
                                  :options="['B', 'M', 'TB']" v-model="adverse.vehicule.etat_general"
                                  :disabled="isDisabled">
                        </v-select>
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">ETAT DES PNEUS</label>-->
                        <v-select class="formInput" placeholder="ETAT DES PNEUS" style="text-transform: uppercase"
                                  :options="['B', 'M', 'TB']" v-model="adverse.vehicule.etat_des_pneus"
                                  :disabled="isDisabled" />
                    </div>

                    <div class="field">
                        <!--<label class="formLabel">DMC</label>-->
                        <!--<input type="text" class="formInput" placeholder="DMC" v-model="vehicule.dmc"/>-->
                        <date-milli class="formInput" v-model="adverse.vehicule.dmc" placeholder="DMC"
                                    :disabled="isDisabled" :showPlaceholder="true"/>
                    </div>
                </div>

                <div class="field">
                    <!--<label class="formLabel">Dépot du véhicule</label>-->
                    <input type="text" class="formInput" placeholder="DÉPOT DU VÉHICULE"
                           v-model="adverse.vehicule.depot_vehicule" v-uppercase :disabled="isDisabled"/>
                </div>

                <!--<div class="three fields">
                    <div class="field">
                        <label class="formLabel">CONSTAT AV</label>
                        <input type="text" class="formInput" placeholder="CONSTAT AV" v-model="dossier.adverse.vehicule.constat_av"/>
                    </div>
                    <div class="field">
                        <label class="formLabel">CONSTAT EN</label>
                        <input type="text" class="formInput" placeholder="CONSTAT EN" v-model="dossier.adverse.vehicule.constat_en"/>
                    </div>
                    <div class="field">
                        <label class="formLabel">CONSTAT AP </label>
                        <input type="text" class="formInput" placeholder="CONSTAT AP" v-model="dossier.adverse.vehicule.constat_ap"/>
                    </div>
                </div>-->
            </div>
        </div>

    </div>
</template>

<script>
    import dateMilli from "@/components/dateMilli.vue";

    export default {
        name: "adverse",
        components: {dateMilli},
        props: {
            adverse_prop: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            assurance_list_prop: {
                type: Array,
                default: function () {
                    return []
                }
            },
            marque_list_prop: {
                type: Array,
                default: function () {
                    return []
                }
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            id_doc:{
                type: String,
                default: ""
            },
            adverse_key:{
                type: String,
                default: ""
            },
            type_reference:{
                type: String,
                default: ""
            }
        },
        data() {
            return {
                adverse: {
                    assurance: null,
                    courtier: null,
                    vehicule: {}
                },
                assurance_list: [],
                courtier_list: [],
                marque_list: [],
                model_list: [],
                dossier_by_immat: [],
                energie_list: ['DIESEL', 'ELECTRIQUE', 'ESSENCE']
            }
        },
        methods: {
            getCourtierList() {
                log('getCourtierList');
                let data = {
                    collection: this.$SHARED.collections.courtier,
                    query: {},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                if (this.adverse.assurance && this.adverse.assurance._id) {
                    data.query.assurance = this.adverse.assurance._id
                }
                this.$store.requestFind(data).then(ar => {
                    this.courtier_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getModels() {
                log('getModels');
                let data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {type: 'model'},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };

                if (this.adverse.vehicule && this.adverse.vehicule.marque && this.adverse.vehicule.marque._id) {
                    data.query.id_pere = this.adverse.vehicule.marque._id
                }

                this.$store.requestFind(data).then(ar => {
                    this.model_list = ar.docs;
                }, code => {
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});
                })
            },
            getDossierByImmat(){
                if(!this.adverse.vehicule.n_immat){
                    this.dossier_by_immat = [];
                    return;
                }
                let data = {
                    collection: this.$SHARED.collections.documents,
                    query: {
                        $or: [
                            {"vehicule.n_immat": this.adverse.vehicule.n_immat},
                            {"adverse.vehicule.n_immat": this.adverse.vehicule.n_immat},
                            {"adverse_second.vehicule.n_immat": this.adverse.vehicule.n_immat}
                        ]
                    },
                    options: {page: 1, limit: -1}
                };
                //data.query[this.adverse_key + '.vehicule.n_immat'] = this.adverse.vehicule.n_immat

                if(this.id_doc){
                    data.query._id = {$ne: this.id_doc}
                }

                this.$store.requestFind(data).then(ar => {
                    this.dossier_by_immat = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            }
        },
        mounted() {
            this.adverse = this.adverse_prop;
            this.assurance_list = JSON.parse(JSON.stringify(this.assurance_list_prop));
            this.marque_list = JSON.parse(JSON.stringify(this.marque_list_prop));
            this.getCourtierList();
            this.getModels();
            $('.immat-popup').popup({on: 'click',inline: false});
        }
    }
</script>

<style scoped>
    .ui.accordion .active.title .angle.right,
    .ui.accordion .accordion .active.title .angle.right {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .ui.accordion.menu .item .active.title > .angle.right {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .static-value {
        position: absolute;
        left: 20px;
        font-weight: bold !important;
        font-size: 1em !important;
        color: var(--main-color-blue) !important;
        top: 10px;
    }
    .immat-popup{
        background-color: #E4F0F4;
        border: 1px solid rgba(13, 68, 132, 0.25)
    }
</style>