<template>
    <div class="ui fluid card tasksCard p-half" >
        <div class="content p-0" >
            <div class="description contentHeader">Documents téléchargés</div>
            <div class="ui feed" style="margin-top:0.3em;max-height: 180px; overflow: auto; text-transform: uppercase" v-if="generated_files.length">
                <div class="event" v-for="(file, index) in generated_files">
                    <div class="label small-avatar">
                        <avatar :user="file.user"/>
                    </div>
                    <div class="content">
                        <!-- <div class="date">
                            
                        </div> -->
                        <div class="summary small-text" :class="{'first-elem': index ===0}">
                           <span class="text-gray">{{ file.date_creation | moment('L LT') }}</span>  |
                            <span>{{ file.user.username }}</span>  |
                            <a :href="server_address + file.path" target="_blank"><u>{{ file.label }} <i class=" download icon"></i></u></a>
                        </div>
                        <!--<div class="extra images" v-if="file.file_type === 'image'">
                            <a :href="server_address + file.path" target="_blank"><img :src="server_address + file.path"></a>
                        </div>-->
                    </div>
                </div>

            </div>
            <div class="no-content-msg" v-if="!generated_files.length">
                <h2>Aucune activité</h2>
            </div>
        </div>
    </div>
    <!--<table class="ui fixed very basic tablet stackable table folderTable">
        <thead>
        <tr>
            <th>Type</th>
            <th>Operateur</th>
            <th>Date de génération</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="file in generated_files">
            <td>{{ file.type}}</td>
            <td class="tableAvatar">
                <avatar :user="file.user"/>
                <span>{{file.user.username}}</span>
            </td>

            <td class="text-green">{{file.date_creation | moment('calendar')}}</td>
            <td>
                <a :href="$store.state.SERVER_ADDRESS + file.link" target="_blank"><i class=" download icon"></i></a>
            </td>
        </tr>

        </tbody>
    </table>-->
</template>

<script>
    export default {
        name: "history_images",
        props: {
            attachment_prop: {type: Array},
            filterBy: {
                type: Object,
                default: function () {
                    return {type: 'generated'}
                }
            },
            isRemove:{type:Boolean, default: false}
        },
        computed:{
            generated_files(){
                return _[this.isRemove ? 'reject' : 'filter'](this.attachment_prop, this.filterBy)
            },
            server_address(){
                return this.$store.state.SERVER_ADDRESS +'/';
            }
        }
    }
</script>

<style scoped>
    .first-elem *{
        color: #ff0000 !important;
    }
</style>