<template>
    <VueCtkDateTimePicker locale="fr" v-model="date" :only-date="true" :no-label="true" :label="label"
                          :min-date="minDate"
                          buttonNowTranslation="Maintenant" format="YYYY-MM-DD" formatted="L"/>
</template>

<script>
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    export default {
        name: "dateMili",
        props:{
            label: {
                type: String,
                default: ""
            },
            minDate:{
                type: String,
                default: null
            }
        },
        data(){
            return{
                date: null,
            }
        },
        methods:{
            convertToMillis(dateOutput){
                return dateOutput !=null ? this.$moment(dateOutput).valueOf() : null;
            },
            convertToDate(dateInput){
                return dateInput ? this.$moment(dateInput).format("YYYY-MM-DD") : null;
            },
        },
        watch:{
            date:function(chosenDate){
                this.$emit('input', chosenDate ? this.convertToMillis(chosenDate) : null);
            },
            value:function(incomingDate){
                this.date = this.convertToDate(incomingDate)
            }
        }
    }
</script>

<style scoped>

</style>