<template>
    <div class="card actionsCard p-half">
        <div class="ui grid container" style="width: 100%; padding:0 10px">
            <div class="centered row">
                <div class="sixteen wide column">
                    <div class="ui form p-1">
                        <div class="field">
                            <label class="formLabel">
                                <h3>Message </h3>
                            </label>
                            <textarea v-model="message" class="formInput" id="" rows="3" placeholder="Message"></textarea>
                        </div>
                        <div class="button">
                            <button class="ui right floated button success-button" @click="valider" :class="{'loading':isBusy}" :disabled="isBusy">Terminer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import 'leaflet/dist/leaflet.css';
    import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
    import 'leaflet-defaulticon-compatibility';
    import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css'
    import 'leaflet-extra-markers'
    import FolderInfo from "@/components/folder-info.vue";
    import L from "leaflet";
    export default {
        name: "validation_task",
       components:{
            FolderInfo
        },
        props:{
            dossier:{type:Object},
            task:{type:Object}
        },
        data(){
            return {
                isBusy:false,
                map: null,
                working_layer:null,
                message:'',
            }
        },
        methods:{
            valider(){
                console.log('valider');
                if(this.isBusy) return;

                this.isBusy = true;
                let query = {
                    task_to_complete: this.task._id,
                    receiver: this.dossier.dispatcher,
                    action: this.$store.getters.findOneAction({_id:'dispatcher'}),
                    message:this.message,
                    document: this.dossier
                };
                this.$store.dispatchAsync(this.$SHARED.services.share, query).then(data => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'success',
                        text: 'rendez-vous valider avec succee'
                    });
                    this.$emit('task_completed')
                }, code => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'error',
                        text: this.$SHARED.messages[code]
                    });
                });
            }
        }
    }
</script>

<style scoped>
    #map {
        height: 100%;
        width: 100%;
        border-radius: 16px;
    }
</style>