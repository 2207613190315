<template>
    <div class="ui fluid card actionsCard">
        <div class="content">
            <div class="header">
                <div class="actionHeader">
                    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path
                                d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                fill="#0953AA"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <path fill="#fff" d="M0 0h20v20H0z" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span class="pl-half">Ajouter un commentaire</span>
                </div>
            </div>

            <div class="description contentHeader">Commentaires</div>

            <div class="description cardInfoContainer">
                <div class="timelineContainer">
                    <div class="item" v-for="task in tasks">
                        <avatar :user="task.receiver"/>
                        <div class="itemDescition">
                            <p v-if="task.message">{{task.message}}</p>
                            <p v-else><i>Aucun Message</i></p>
                            <p class="itemDate"> {{ task.date_creation | moment('calendar') }} <i style="color:grey">{{task.receiver.username}}</i> </p>
                        </div>
                    </div>
                </div>
                <div class="no-content-msg" v-if="!tasks.length">
                    <h2>Aucun Commentaire</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
        components:{
        },
    props:{
        tasks:{type: Array}
    }
};
</script>

<style scoped>
</style>