<template>
    <div class="ui fluid card actionsCard">
        <div class="content ">
            <div class="header">
                <div class="actionHeader">
                    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 10a9.935 9.935 0 012.929-7.071A9.935 9.935 0 0110 0a9.935 9.935 0 017.071 2.929A9.935 9.935 0 0120 10a9.935 9.935 0 01-2.929 7.071A9.935 9.935 0 0110 20a9.935 9.935 0 01-7.071-2.929A9.935 9.935 0 010 10zm18.438 0c0-4.652-3.786-8.438-8.438-8.438-4.652 0-8.438 3.786-8.438 8.438 0 4.652 3.786 8.438 8.438 8.438 4.652 0 8.438-3.786 8.438-8.438zM10 14.074l5.402-5.402-1.105-1.105L10 11.864 5.703 7.567 4.598 8.672 10 14.074z"
                            fill="#0D4484"
                        />
                    </svg>
                    <span class="pl-half">Trier</span>
                </div>
            </div>
            <div class="description contentHeader">Interventions</div>
            <div class="ui feed">
                <div class="event">
                    <div class="label">
                        <img src="@/assets/images/avatar.jpg">
                    </div>
                    <div class="content">
                        <div class="date">
                            3 days ago
                        </div>
                        <div class="summary">
                            You added <a>Jenny Hess</a> to your <a>coworker</a> group.
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="description cardInfoContainer">
                <div class="interventionItem" v-for="task in tasks">
                    <p class="interventionMessage">
                        {{task.action.name}}
                    </p>
                    <p class="interventionType">{{task.receiver.username}}</p>
                    <img src="@/assets/images/avatar.jpg" alt class="avatar" />
                </div>
                <div class="no-content-msg" v-if="!tasks.length">
                    <h2>Aucune Intervention</h2>
                </div>

            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    props:{
        tasks:{type: Array}
    }
};
</script>

<style scoped>

</style>