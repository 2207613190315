<template>
    <form class="ui form" id="form">
        <div class="field">
            <label> Type </label>
            <select v-model="selected_type" style="text-transform: uppercase">
                <option value="ACC_DEV"> ACC DEV </option>
                <option value="AV_DOM"> AV DOM </option>
                <option value="AV_REF"> AV REF </option>
                <option value="autre"> Autre </option>
                <option value="contrat"> CT </option>
                <option value="constat_amiable"> Doc de base </option>
                <option value="devis"> Devis </option>
                <option value="carte_grise"> CG </option>
                <option value="FA"> FA </option>
                <option value="facture"> Facture </option>
                <option value="forfait"> Forfait </option>
                <option value="FTX"> FTX </option>
                <option value="ID"> ID </option>
                <option value="NH"> NH </option>
                <option value="PHADV"> PHADV </option>
                <option value="PHAPR"> PHAPR </option>
                <option value="PHAV"> PHAV </option>
                <option value="PHENC"> PHENC </option>
                <option value="PEC"> PEC </option>
                <option value="RAP"> RAP </option>
                <option value="RAP_PR_CTR"> RAP PR CTR </option>
                <option value="REQ"> REQ </option>
                <option value="relances"> Relances </option>
<!--                <option value="terrain"> Terrain </option>-->
            </select>
        </div>

        <div class="field">
            <label> Attachement </label>
            <div class="upload-file">
                <input type="file" id="file" @change="change" accept="image/*,application/pdf" multiple>
                <div class="upload-content">
                    <svg data-v-f4069962="" width="75px" height="100px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-upload" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="mt-5 mb-2 big-upload-icon svg-inline--fa fa-file-upload fa-w-12"><path data-v-f4069962="" fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z" class=""></path></svg>
                    <h3> {{ selected_file ? selected_file : 'Cliquer ici ou bien glisser votre fichier'}} </h3>
                </div>
            </div>
        </div>

        <div class="action-buttons" style="text-align: right;">
            <button class="ui button" @click.prevent="$modal.hide('upload-modal')">Annuler</button>
            <button class="ui right labeled icon blue button" @click.prevent="upload" :disabled="!there_is_file">
                Upload
                <i class="right upload icon"></i>
            </button>
        </div>

    </form>
</template>

<script>
    export default {
        name: "upload-modal",
        data(){
            return{
                selected_type: 'constat_amiable',
                selected_file: null,
                isBusy: false,
                there_is_file: false
            }
        },
        methods:{
            upload(){
                let data = {
                    form: new FormData($("#form")[0]),
                    file: document.getElementById("file").files,
                    type: this.selected_type
                };
                this.$emit('uploadClick', data);
            },
            change(event){
                let files = event.target.files;
                console.log("@@@@@@@@@@@@@@@", files)
                let unsupported_type = false;
                if(files.length > 20){
                    this.$notify({group: "user-message", type: "error", title: "Alert",
                        text: "Vous ne pouvez pas télécharger plus de 20 fichiers à la fois",
                        duration: 5000
                    });
                    return;
                }
                for (const file of files) {
                    if (file.type !== 'application/pdf' && file.type.split('/')[0] !== 'image') {
                        this.removeFile();
                        this.$notify({
                            group: "user-message", type: "error", title: "Alert",
                            text: "Merci de choisir une format Image ou PDF!",
                            duration: 5000
                        });
                        unsupported_type = true;
                        return;
                    }
                }
                if(unsupported_type) return;

                this.there_is_file = true;
                if(files.length === 1){
                    this.selected_file = files[0].name
                }
                else {
                    this.selected_file = files.length + ' fichiers sélectionnés';
                }
            },
            removeFile(){
                document.getElementById("file").value='';
                this.there_is_file = false;
            }
        }
    }
</script>

<style scoped>
    #file{
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .upload-file{
        border: 2px dashed #051a42;
        position: relative;
        cursor: pointer;
    }
    .upload-content{
        text-align: center;
        padding: 22px 0;
    }
    .upload-content svg{
        margin-top: 0 !important;
        color: rgba(157,166,241,.45)
    }
    .upload-content h3{
        color: var(--main-color-blue)
    }
</style>