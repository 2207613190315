<template>
    <div class="card actionsCard">
        <div class="ui pointing blue secondary menu" style="margin-bottom: 25px">
            <a class="item" :class="{'active': tab==='generation'}" @click="tab='generation'"><i class="add icon"></i>Nouveau</a>
            <a class="item" :class="{'active': tab==='history'}" @click="tab='history'"><i class="history icon"></i>Historique</a>
            <div class="right  menu">
                <a class="ui  item" style="color:red" @click="confirmationCloture" :disable="isBusy"><i class="window close icon"></i>Terminer</a>
            </div>
        </div>

        <template v-if="tab === 'generation'">
             <div class="button" style="margin-top:1.5em">
                <button class="ui right labeled icon big button danger-button" :class="{'loading':isBusy}"
                        :disabled="isBusy" @click="generateReport">
                    <i class="file pdf icon"></i>Générer Rapport
                </button>
            </div>
        </template>

        <template v-else-if="tab === 'history'">
            <generatedFilesTable :attachment_prop="attachment_prop" :filterBy="{type:'generated', task_template: template_name}"/>
        </template>

        <confirm-cloture @clotureConfirmed="completeTask" />

    </div>
</template>

<script>
    import generatedFilesTable from "@/components/document/generated_files_table.vue";
    import confirmCloture from "@/components/modals/confirm-cloture.vue";
    export default {
        name: "etablir_forfait",
        components:{
            generatedFilesTable,
            confirmCloture
        },
        props:{
            dossier:{type:Object},
            task:{type:Object},
            attachment_prop:{type:Array},
        },
        data() {
            return {
                isBusy:false,
                tab:'generation',
                pdf_template_options: {
                    id_doc: this.dossier._id,
                },
                template_name: 'etablir_forfait'
            }
        },
        methods:{
            confirmationCloture(){
                console.log('`confirmationMessage`');
                this.$modal.show("cloture-modal")
            },
            completeTask(message){
                console.log('completeTask');
                if(this.isBusy) return;

                this.isBusy=true;
                let query = {
                    task_to_complete: this.task._id,
                    receiver: this.dossier.dispatcher,
                    action:this.$store.getters.findOneAction({_id:'dispatcher'}),
                    message: message,
                    document: this.dossier,
                    car_location: null
                };
                this.$store.dispatchAsync(this.$SHARED.services.share, query).then(data => {
                    this.isBusy=false;
                    this.$notify({group: 'user-message', type: 'success',
                        text: 'rendez-vous valider avec succee'
                    });
                    this.$emit('task_completed')
                }, code => {
                    this.isBusy=false;
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                });
            },
            generateReport(){
                if(!this.dossier.assurance._id){
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: 'Sélectionnez une assurance!'
                    });
                    return;
                }
                console.log('generateReport');
                this.isBusy=true;
                let template = ''

                if(this.dossier.assurance._id.toLowerCase() === 'sanad'){
                    template = 'forfait_sanad'
                }
                else {
                    template = 'forfait_general'
                }
                let query={
                    id_doc: this.dossier._id,
                    task_template: this.template_name,
                    pdf_template_name: template,
                    pdf_template_options: this.pdf_template_options
                };

                this.$store.dispatchAsync(this.$SHARED.services.generatePDF, query).then((data) => {
                    let link=this.$store.state.SERVER_ADDRESS + data.link;
                    console.log("link", link);
                    window.open(link);
                    this.$emit('refresh_attachments')
                    this.isBusy=false;
                }).catch(error=>{
                    this.isBusy= false;
                    this.$notify({group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            }
        },

    }
</script>

<style scoped>
    .add-button{
        height: 48px;
        padding: 0 6px 0 15px !important;
        margin-top: 23px;
    }
    table th{
        line-height: 31px !important;
        padding-bottom: 0 !important;
    }
</style>