<template>
    <div class="card actionsCard">
        <div class="ui pointing blue secondary menu" style="margin-bottom: 25px">
            <a class="item" :class="{'active': tab==='generation'}" @click="tab='generation'"><i class="add icon"></i>Nouveau</a>
            <a class="item" :class="{'active': tab==='history'}" @click="tab='history'"><i class="history icon"></i>Historique</a>
            <div class="right  menu">
                <a class="ui  item" style="color:red" @click="confirmationCloture" :disable="isBusy"><i class="window close icon"></i>Terminer</a>
            </div>
        </div>

        <template v-if="tab === 'generation'">
            <div class="fourteen wide column">
                    <h3 style="margin-bottom: 1.5em">Options de generation</h3>
                    <div class="ui form">
                        <div class="field">
                            <label>Option 1</label>
                            <input class="formInput" type="text" placeholder="option 1">
                        </div>
                        <div class="field">
                            <label>Note sur le rapport</label>
                            <textarea v-model="pdf_template_options.note" class="formInput" id="" rows="3" placeholder="Note"></textarea>
                        </div>
                    </div>
            </div>
            <div class="button" style="margin-top:1.5em">
                <button class="ui right labeled icon big button danger-button" :class="{'loading':isBusy}" :disabled="isBusy" @click="generateReport"><i class="file pdf icon"></i>Générer Rapport</button>
            </div>
        </template>

        <template v-else-if="tab === 'history'">
            <generatedFilesTable :attachment_prop="attachment_prop" :filterBy="{type:'generated', sous_type:'attestation_de_travail'}"/>
        </template>


    </div>
</template>

<script>
    import generatedFilesTable from "@/components/document/generated_files_table.vue";
    export default {
        name: "generate_report",
        components:{
            generatedFilesTable
        },
        props:{
            dossier:{type:Object},
            task:{type:Object},
            attachment_prop:{type:Array},
        },
        data() {
            return {
                isBusy:false,
                tab:'generation',
                pdf_template_options: {
                    note:''
                },
                message: 'Document généré'
            }
        },
        methods:{
            confirmationCloture(){
                console.log('`confirmationMessage`')
                this.$modal.show('dialog', {
                    title: 'Attention!',
                    text: 'Vous voulez vraiement terminer la tache',
                    buttons: [
                        {
                            title: '<h4 style="color: #808080">Annuler</h4>',
                            default: true // gets clicked by default if you pushed enter
                        },
                        {
                            title: '<h4 style="color: red">Terminer</h4>',
                            handler: () => {
                                this.completeTask();
                                this.$modal.hide('dialog')
                            }
                        }
                    ]
                })
            },
            completeTask(){
                console.log('completeTask');
                if(this.isBusy) return;

                this.isBusy=true;
                let query = {
                    task_to_complete: this.task._id,
                    receiver: this.dossier.dispatcher,
                    action: this.$store.getters.findOneAction({_id:'dispatcher'}),
                    message:this.message,
                    document: this.dossier,
                    car_location: null
                };
                this.$store.dispatchAsync(this.$SHARED.services.share, query).then(data => {
                    this.isBusy=false;
                    this.$notify({group: 'user-message', type: 'success',
                        text: 'rendez-vous valider avec succee'
                    });
                    this.$emit('task_completed')
                }, code => {
                    this.isBusy=false;
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                });
            },
            generateReport(){
                console.log('generateReport');
                this.isBusy=true;
                let query={id_doc: this.dossier._id , pdf_template_name:'attestation_de_travail', pdf_template_options: {id_member: 'some option'}};

                this.$store.dispatchAsync(this.$SHARED.services.generatePDF, query).then((data) => {
                    let link=this.$store.state.SERVER_ADDRESS + data.link;
                    console.log("link", link);
                    window.open(link);
                    this.$emit('refresh_attachments')
                    this.isBusy=false;
                }).catch(error=>{
                    this.isBusy= false;
                    this.$notify({group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            }
        },

    }
</script>

<style scoped>

</style>