<template>
    <!--<div>
        <p class="itemInfoLabel">N° dossier</p>
        <p class="itemInfoValue">{{dossier.numero ? dossier.numero :'-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;'}}</p>
        <p class="itemInfoLabel">Immatriculation</p>
        <p class="itemInfoValue">{{dossier.vehicule.n_immat ? dossier.vehicule.n_immat :'-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;'}}</p>
        <p class="itemInfoLabel">Marque</p>
        <p class="itemInfoValue">{{dossier.vehicule.marque ? dossier.vehicule.marque.label :'-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;'}}</p>
        <p class="itemInfoLabel">Assuré</p>
        <p class="itemInfoValue" v-if="dossier.propri.nom || dossier.propri.prenom">{{dossier.propri.nom ? dossier.propri.nom :''}} {{ dossier.propri.prenom ?  dossier.propri.prenom :''}}</p>
        <p class="itemInfoValue" v-else> &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; </p>
        <p class="itemInfoLabel">N° Téléphone</p>
        <p class="itemInfoValue">{{dossier.propri.tel ? dossier.propri.tel :'-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;'}}</p>

    </div>-->
    <div class="flex" style="text-transform: uppercase">
        <p class="itemInfoValue">
            <span>{{dossier.numero ? dossier.numero : ''}}</span> | <span> {{ dossier.propri.nom   + ' ' + dossier.propri.prenom }} </span> <span class="ml-20"> {{ dossier.propri.tel }} </span>
            | <span>{{dossier.vehicule.marque && dossier.vehicule.marque.label ? dossier.vehicule.marque.label : ''}}</span> | <span class="ml-20">{{dossier.vehicule.model && dossier.vehicule.model.label? dossier.vehicule.model.label : ''}}</span>
            |  <span class="ml-20">{{dossier.vehicule.n_immat ? dossier.vehicule.n_immat : ''}}</span>
        </p>
        <!-- <p class="itemInfoValue">
            
            
        </p>

        <p class="itemInfoValue">
            
            
           
        </p> -->
    </div>
</template>

<script>
export default {
    props:{
        dossier:{type:Object}
    }
};
</script>

<style scoped>
    .ml-20{
        margin-left: 8px;
    }
</style>