<template>
  <div>
      <button class="ui tiny button flex ml-auto" @click="$modal.show('image-terrain')"> <i class="images icon"></i>Image du terrain {{ attachment_list.length }}</button>
      <modal name="image-terrain" class="no-overflow" :pivotY="0.2" :adaptive="true" width="60%" height="auto" :scrollable="true">
            <div class="ui fluid card actionsCard">
        <div class="content">
            <!--<div class="header">
                <div class="actionHeader" style="align-items: end" @click="isEdit = true" v-if="!isEdit">
                    <i class="edit icon"></i>
                    <span>Editer</span>
                </div>
                <div class="actionHeader" style="align-items: end" @click="isEdit = false" v-else>
                    <i class="times circle icon"></i>
                    <span>Annuler</span>
                </div>
            </div>-->
            <div class="description contentHeader">
                Image du terrain
                <span class="count">{{ attachment_list.length }}</span>
            </div>
            <div class="description cardInfoContainer flexible" >
                    <div class="imageItem" v-for="attachment in attachment_list" style="position: relative">
                        <div class="ui task" :class="{ 'checkbox' : isEdit }" >
<!--                            <input v-if="isEdit" type="checkbox" :value="attachment._id"-->
<!--                                   v-model="attachment.is_active"/>-->
                            <div class="ui checkbox radio-on-image" >
                                <input v-if="!isEdit" type="checkbox" :value="attachment._id"
                                       v-model="checkedImages" name="default"/>
                                <label></label>
                            </div>

                            <label class="taskLabel">
                                <img class="ui bordered rounded image select-image cursor-pointer" style="padding: 1px"
                                     @click="showImage(attachment)"
                                     :src="server_address + attachment.path" alt/>
                            </label>
                        </div>
                    </div>

                <div class="no-content-msg" v-if="!attachment_list.length" style="width: 100%">
                    <h2>Aucune Image</h2>
                </div>
            </div>
            <div class="exta content flex justify-right" v-if="attachment_list.length && checkedImages.length">
<!--                <button class="ui button success-button" @click="validate" v-if="isEdit">Valider</button>-->
                <v-select :options="attachmentType" label="label" v-model="selected_type"
                          class="no-right-border-radius" style="width: 100%; text-transform: uppercase">
                </v-select>
                <button class="ui button success-button add-button" @click="updateType" :disabled="!selected_type">
                    Ajouter
                </button>
            </div>

        </div>
    </div>
      </modal>
  </div>
</template>

<script>
    import uploadModal from "@/components/modals/upload-modal.vue";
export default {
    name: 'image-terrain',
    props:{
        attachment_prop: Array
    },
    data(){
        return{
            current_default:null,
            attachment_list:[],
            checkedImages: [],
            selected_type: '',
            isEdit: false,
            attachment_type: [
                {id: 'PHAV', label: 'PHAV'},
                {id: 'PHAPR', label: 'PHAPR'},
                {id: 'PHENC', label: 'PHENC'},
                {id: 'constat_amiable', label: 'Doc de base'},
                {id: 'carte_grise', label: 'CG'},
                {id: 'contrat', label: 'CT'},
                {id: 'devis', label: 'Devis'},
                {id: 'facture', label: 'Facture'},
                {id: 'ACC_DEV', label: 'ACC DEV'},
                {id: 'RAP_PR_CTR', label: 'RAP PR CTR'},
                {id: 'AV_REF', label: 'AV REF'},
                {id: 'RAP', label: 'RAP'},
                {id: 'ID', label: 'ID'},
                {id: 'autre', label: 'Autre'},
                {id: 'REQ', label: 'REQ'},
                {id: 'forfait', label: 'Forfait'},
                {id: 'FA', label: 'FA'},
                {id: 'relances', label: 'Relances'},
                {id: 'AV_DOM', label: 'AV DOM'},
                {id: 'PEC', label: 'PEC'},
                {id: 'FTX', label: 'FTX'},
                {id: 'NH', label: 'NH'},
                {id: 'PHADV', label: 'PHADV'},
            ]
        }
    },
    components:{
        uploadModal
    },
    methods:{
        updateType(){
            log('updateType');
            const data = {
                collection: this.$SHARED.collections.attachment,
                query: {_id: {$in: this.checkedImages}},
                update: {
                    '$set': {
                        type: this.selected_type.id,
                        label: this.selected_type.label
                    }
                },
                options: {multi: true}
            }
            this.$store.requestUpdate(data).then(() => {
                this.$emit('updateCompleted');
                this.checkedImages = [];
                this.$notify({group: 'user-message', type: 'success', text: this.$SHARED.messages.document.update_succeeded});
            }, code => {
                this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});
            });
        },
        getAttachmentList(){
            log('getAttachmentList');
            const data = {
                collection: this.$SHARED.collections.attachment,
                query: {
                    type: 'terrain'
                },
                options: {
                    page: 1,
                    limit: -1
                }
            };
            this.$store.requestFind(data).then(ar => {
                this.attachment_list = ar.docs;
            }, code => {
                this.$notify({group: 'user-message', type: 'error',
                    text: this.$SHARED.messages.technical_error
                });
            })
        },
        showImage(image){
            let url = this.$router.resolve({
                name: 'imageViewer',
                params: {
                    image_src: image.path,
                    image_type: image.type,
                    image_id_doc: image.id_doc
                }
            })
            window.open(url.href, "", 'location=yes,height=570,width=1136,scrollbars=yes')
        }
    },
    computed:{
        server_address(){
            return this.$store.state.SERVER_ADDRESS + "/";
        },
        attachmentType(){
            return _.orderBy(this.attachment_type, 'label', 'asc')
        }
    },
    watch:{
        attachment_prop(){
            this.attachment_list = _.filter(this.attachment_prop, {type: 'terrain'});
        }
    },
    mounted() {
        this.attachment_list = _.filter(this.attachment_prop, {type: 'terrain'});
    }
};
</script>

<style scoped>
    .cursor-pointer{
        cursor: pointer;
    }
    .justify-right{
        justify-content: flex-end !important;
    }
    .radio-on-image{
        position: absolute;
        z-index: 2;
        left: 5px;
        top: 4px;
    }
    .add-button{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
</style>