<template>
    <div class="card actionsCard">
        <div class="sixteen wide column">
            <div class="ui form">
                <div class="inline fields" style="margin-bottom:0.2em !important;">
                    <div class="four wide field flex items-center">
                        <!--<label style="white-space: nowrap;">ACTION: </label>-->
                        <div class="w-100 pl-half">
                            <v-select label="name" class="formInput" :options="action_list" placeholder="ACTION"
                                      v-model="selected_action" :filterable="true" @input="onActionChange"/>
                        </div>
                    </div>

                    <div class="four wide field" v-if="selected_action && selected_action._id === 'revus'">
                        <!--<label> La date d'activation </label>-->
                        <date-milli v-model="date_activation" class="datePicker" :min-date="$moment().format('YYYY-MM-DD')"
                                    label="DATE D'ACTIVATION"/>
                    </div>

                    <div class="four wide field" v-else-if="selected_action &&
                            (selected_action._id !== 'prendre_photo_enc' && selected_action._id !== 'prendre_photo_apr')">
                        <!--                    <label style="white-space: nowrap;">ASSIGNE A:</label>-->
                        <UserGroupSelect class="formInput" placeholder="ASSIGNE A"
                                         v-model="selected_user" :filterFront="user_filter"/>
                    </div>

                    <div class="six wide field">
                        <!--<label class="formLabel">
                            <h5  style="white-space: nowrap;">Observations : </h5>
                        </label>-->
                        <div class="w-100 pl-half">
                            <textarea v-model="message" class="formInput" id="" rows="2"
                                      placeholder="OBSERVATIONS"></textarea>
                        </div>
                    </div>

                    <div class="two wide field">
                        <div class="button">
                            <button class="ui right floated button success-button" @click="dispatch"
                                    :class="{'loading':isBusy}" :disabled="isBusy">Dispatch
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="inline fields m-0">
                 
                </div> -->
            </div>
        </div>

        <div class="sixteen wide column">
            <users-map v-if="selected_action && (selected_action._id === 'prendre_photo_enc' || selected_action._id === 'prendre_photo_apr')"
                       @selectedUser="selectedUser" @selectedPosition="selectedPosition" />
        </div>
    </div>
</template>

<script>
    import UserGroupSelect from "@/components/UserGroupSelect.vue";
    import dateMilli from "@/components/templates/dateMiliDatePicker.vue";
    import usersMap from "@/components/map.vue";

    export default {
        name: "dispatcher",
        components: {
            dateMilli,
            UserGroupSelect,
            usersMap
        },
        props: {
            dossier: {type: Object},
            task: {type: Object}
        },
        data() {
            return {
                isBusy: false,
                selected_user: null,
                message: '',
                selected_action: null,
                delai: null,
                user_filter: null,
                date_activation: null,
                selected_user_map: null,
                selected_position: null,
            }
        },
        computed: {
            action_list() {
                return this.$store.getters.findAction({dispatchable: true})
            }
        },
        methods: {
            dispatch() {
                console.log('dispatch');
                if(this.isBusy)
                    return;
                this.isBusy = true;
                let query = {
                    task_to_complete: this.task._id,
                    id_doc: this.dossier._id,
                    receiver: this.selected_user,
                    action: this.selected_action,
                    message: this.message,
                    document: this.dossier
                };

                if (this.selected_action._id === 'revus') {
                    if(!this.date_activation){
                        this.$notify({
                            group: 'user-message', type: 'error',
                            text: "La Date d'Activation est obligatoire"
                        });
                        this.isBusy = false
                        return;
                    }
                    query.receiver = this.task.receiver;
                    query.date_activation = this.date_activation
                }
                else if(this.selected_action._id === 'prendre_photo_enc' || this.selected_action._id === 'prendre_photo_apr'){
                    query.receiver = this.selected_user_map
                    if(this.selected_position)
                        query.location = this.selected_position;
                }
                log(query);

                this.$store.dispatchAsync(this.$SHARED.services.share, query).then(data => {
                    this.isBusy = false;
                    this.$notify({
                        group: 'user-message', type: 'success',
                        text: 'Tâche envoyée avec succès'
                    });
                    this.$emit('task_completed')
                }, code => {
                    this.isBusy = false;
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages[code]
                    });
                });
            },
            onActionChange() {
                if (this.selected_action._id === 'prendre_photo_enc' || this.selected_action._id === 'prendre_photo_apr') {
                    this.user_filter = {type: 'mobile'}
                } else {
                    this.user_filter = null
                }
            },
            selectedPosition(position){
                console.log("POSITION", position)
                this.selected_position = position
            },
            selectedUser(user){
                console.log("USER", user)
                this.selected_user_map = user
            }
        }

    }
</script>

<style scoped>
</style>