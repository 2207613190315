<template>
    <div class="ui grid m-0 p-0">
        <confirmCreateDossier @creationConfirmed="createDossier" v-if="!isEdit"></confirmCreateDossier>
        <div class="ui grid row p-0 m-0">
            <infoSidebar/>
            <div class="eleven wide column p-0">
                <navbar :title="isEdit ? dossier.numero : ''"/>
                <div class="ui grid m-0">
                    <div class="row pb-0 " style="padding-top: 0.3em;align-items: flex-end;" >
                        <div class="twelve wide column">
                            <folder-info :dossier="dossier"/>
                        </div>


                    </div>
                    <div class="row p-half">
                        <div class="six wide column">
                            <history-images :attachment_prop="attachment_list" :isRemove="true"
                                            :filterBy="{type:'generated'}"/>
                        </div>
                        <div class="right floated ten wide column">
                            <folder-tasks :tasks="dossier_tasks" :dossier="dossier" :isEdit="isEdit"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui grid row container m-0 p-0">
            <template v-if="isEdit">
                <!-- <div class="row centered">
                    <div class="fifteen wide column">
                        <h2 class="ui header subjectHeader">Interventions et Commentaires</h2>
                    </div>
                </div> -->
                <div class="ui grid row mx-1 pt-0">
                    <!--<div class="five wide column">
                        <folder-comments :tasks="dossier_tasks"/>
                    </div>-->
                    <div class="six wide right floated column py-0">
                                    <image-terrain :attachment_prop="attachment_list" @updateCompleted="getAttachmentList"/>
                    </div>
                    <div class="row centered p-0" v-if="is_suspended" >
                    <div class="content p-half">
                            <h3 class="ui icon header dossier-status m-0" style="color:#FF9900">
                                <i class="power off icon status-icon"></i>
                                En instance |
                                 La date d'activation:
                                {{ $store.getDate(suspended_tasks[0].date_activation, "DD/MM/YYYY") }}
                            </h3>
                            <div class="ui button" style="background-color: #FF9900; color: #ffffff" @click="$modal.show('revue_modal')">
                                Mise en circulation
                            </div>

                        </div>
                    </div>
                    <!--completed-->
                    <div class="row centered p-0"
                         v-if="dossier.status === 'cloturer'">
                        <!-- <div class="ui inverted active dimmer" style="z-index: 900;">

                        </div> -->
                        <div class="content p-half">
                                <h3 class="ui icon header dossier-status m-0" style="color:#0fb637;" >
                                    <i class="check icon status-icon"></i>Complete
                                </h3>
                                <div class="ui form">
                                    <div class="two fields">
                                        <div class="field">
                                            <date-milli v-model="dossier.date_paiement"/>
                                        </div>

                                        <div class="field">
                                            <input type="text" v-model="dossier.paiement_input">
                                        </div>

                                        <button class="ui positive button" @click="dossier_payed">
                                            Payé
                                        </button>

                                        <button class="ui primary button" @click="confirmationActivateCloture" :disabled="isBusy">
                                            Réactiver
                                        </button>
                                    </div>

                                </div>

                            </div>
                    </div>

                    <!--payed-->
                    <div class="row centered p-0"
                         v-if="dossier.status === 'paye'">
                        <!-- <div class="ui inverted active dimmer" style="z-index: 900;">

                        </div> -->
                         <div class="content p-half">
                             <h3 class="ui icon header dossier-status m-0" style="color:#0fb637">
                                 <i class="dollar sign icon status-icon"></i>
                                 Payé |
                                 La date de paiement : {{$store.getDate(dossier.date_paiement, "DD/MM/YYYY")}}
                                 <span class="edit-paiement" @click="showDatePaiement = !showDatePaiement" title="changer la date de paiement">
                                     <i :class="showDatePaiement ? 'x' : 'edit'" class="icon"></i>
                                 </span>

                                 <span class="edit-paiement" title="Réactiver" @click="confirmationActivateCloture"
                                       style="margin-left: 8px;">
                                     <i class="tiny refresh icon"></i>
                                 </span>
                             </h3>
                             <div class="ui form" v-if="showDatePaiement">
                                 <div class="field" style="display: inline-flex">
                                     <date-milli v-model="dossier.date_paiement"/>
                                 </div>
                                 <button class="ui positive button" style="display: inline-flex;margin-left: 12px;" @click="dossier_payed">
                                     Valider
                                 </button>
                             </div>
                         </div>
                    </div>

                    <template v-if="task">
                        <div class="row centered p-half">
                            <div class="sixteen wide column">
                                <h3 class="ui header subjectHeader" style="margin-bottom: 0 !important;">
                                    {{task.action.name}}</h3>
                            </div>
                        </div>
                        <div class="row centered p-0">

                        <component class="sixteen wide column" v-if="task.action.task_status === 'active'"
                                   :is="task.action.template" :task="task" :dossier="dossier"
                                   :attachment_prop="attachment_list" @refresh_attachments="getAttachmentList"
                                   @task_completed="task_completed">
                        </component>
                            <!--<component v-if="task.action.task_status === 'active'" class="row centered"
                                       :is="'cloture'" :task="task" :dossier="dossier"
                                       :attachment_prop="attachment_list" @refresh_attachments="getAttachmentList"
                                       @task_completed="task_completed">
                            </component>-->
                        </div>

                    </template>
                    


                </div>

            </template>

            <!-- <div class="row centered">
                <div class="fifteen wide column">
                    <h2 class="ui header subjectHeader">
                        Element du dossier
                        <span style="font-size: 22px;margin-left: 23px; cursor: pointer" @click="isDisabled = !isDisabled">
                            <i :class="isDisabled ? 'small edit icon' : 'small times circle icon'" style="margin-right: 0;"></i>
                            <u>{{isDisabled ? 'Editer' : 'Annuler'}} </u>
                        </span>
                    </h2>
                </div>
            </div> -->
            <div class="row centered">

                <div :class="isEdit ? 'ten wide column pl-1-5' : 'fifteen wide column'">
                    <div class="flex justify-between align-center">

                        <div class="tabs">
                            <div
                                    class="tab"
                                    :class="{active: currentTab ==='AssuranceTab'}"
                                    @click="currentTab = 'AssuranceTab'"
                            >
                                <p>Général <i class="exclamation small triangle icon" style="color:#ff9500"
                                              v-if="!dossier.assurance"></i></p>

                            </div>
                            <div
                                    class="tab"
                                    :class="{active: currentTab ==='VehiculeTab'}"
                                    @click="currentTab = 'VehiculeTab'"
                            >
                                <p>Assuré<i class="exclamation small triangle icon" style="color:#ff9500"
                                            v-if="!dossier.vehicule.marque"></i></p>
                            </div>

                            <div
                                    class="tab"
                                    :class="{active: currentTab ==='AdverseTab'}"
                                    @click="currentTab = 'AdverseTab'"
                            >
                                <p>
                                    ADV
                                    <!--<i class="exclamation small triangle icon" style="color:#ff9500" v-if="!dossier.vehicule_adverse.marque"></i>-->
                                </p>
                            </div>

                            <div
                                    class="tab"
                                    :class="{active: currentTab ==='AdverseSecondTab'}"
                                    @click="currentTab = 'AdverseSecondTab'"
                            >
                                <p>
                                    ADV 2
                                    <!--<i class="exclamation small triangle icon" style="color:#ff9500" v-if="!dossier.vehicule_adverse.marque"></i>-->
                                </p>
                            </div>

                            <div
                                    v-if="dossier.type_dossier"
                                    class="tab"
                                    :class="{active: currentTab ==='EstimationTab'}"
                                    @click="currentTab = 'EstimationTab'"
                            >
                                <p>CHIFF</p>
                            </div>
                        </div>
                        <div class="button flex items-center">

                            <span class="borderless-btn yellow" v-if="isEdit" @click="isDisabled = !isDisabled">
                                <i :class="isDisabled ? 'small edit icon' : 'small times circle icon'"
                                   style="margin-right: 0;"></i>
                                <u>{{isDisabled ? 'Editer' : 'Annuler'}} </u>
                            </span>
                            <button class="borderless-btn" :class="{'disabled':isBusy}" :disabled="isBusy"
                                    @click="save">
                                <i class="save outline icon" style="margin-right: 0;"></i>
                                <u>Enregistrer </u>
                            </button>
                            <button class="borderless-btn red" @click="$router.push({name: 'home'})">
                                <i class="close icon" style="margin-right: 0;"></i>
                                <u>Annuler</u>
                            </button>

                            <span class="borderless-btn" @click="$modal.show('dynamic-modal')" v-if="isEdit && dossier_prop.adverse.assurance" style="margin-right: 15px">
                                <i class="large copy outline icon" title="DUPLIQUER" style="margin-right: 2px;"></i>
                                <!--<u>DUPLIQUER</u>-->
                            </span>
                            <!-- <button class="ui small right floated button success-button" :class="{'loading':isBusy}" :disabled="isBusy" @click="save">Enregistrer</button>
                            <button class="ui small right floated button" @click="$router.push({name: 'home'})">Annuler</button> -->
                        </div>
                    </div>

                    <!--assurance tab-->
                    <div id="assurance_form" v-if="currentTab==='AssuranceTab'">
                        <div class="tabTitleContainer">
                            <div class="tabTitle">Compagnie</div>
                            <div class="line"></div>
                        </div>
                        <div class="ui equal width form p-1">

                            <div class="three fields">
                                <div class="four wide field">
                                    <!--<label class="formLabel">Compagnie</label>-->
                                    <v-select :clearable="false" label="label" :options="assurance_list" class="formInput"
                                              v-model="dossier.assurance" @input="getCourtierList(); getHonoraire()" :disabled="isEdit" placeholder="COMPAGNIE">
                                    </v-select>
                                </div>

                                <div class="eight wide field">
                                    <!--<label class="formLabel">Agent/Courtier</label>-->
                                    <v-select label="label" :options="courtier_list" class="formInput" placeholder="AGENT/COURTIER"
                                              v-model="dossier.courtier" :disabled="!dossier.assurance || isDisabled">
                                    </v-select>
                                </div>

                                <div class="four wide field">
                                    <!--<label class="formLabel">Référence cie</label>-->
                                    <input type="text" class="formInput" placeholder="RÉFÉRENCE CIE"
                                           v-model="dossier.reference_cie" v-uppercase :disabled="isDisabled"/>
                                </div>

                            </div>

                            <div class="four fields">
                                <div class="field">
                                    <!--<label class="formLabel">Police</label>-->
                                    <input type="text" class="formInput" placeholder="POLICE"
                                           v-model="dossier.vehicule.police" v-uppercase :disabled="isDisabled"/>
                                </div>

                                <div class="field" v-if="dossier.assurance">
                                    <!--<label class="formLabel">DATE SINISTRE</label>-->
                                    <date-milli class="formInput" v-model="dossier.assurance.date_sinistre" :maxDate="new Date().toISOString().split('T')[0]"
                                                :disabled="isDisabled" placeholder="D.SIN" :showPlaceholder="true"/>
                                </div>

                                <div class="field">
                                    <!--<label class="formLabel">Cadre d'expertise</label>-->
                                    <v-select class="formInput" placeholder="CADRE D'EXPERTISE"
                                              :options="cadre_expertise" @input="getHonoraire()"
                                              v-model="dossier.nature" :disabled="isDisabled">
                                    </v-select>
                                </div>

                                <div class="field">
                                    <v-select class="formInput" placeholder="EXPERT ADVERSE"
                                              :options="expert_adverse_list" :reduce="expert => expert.label"
                                              v-model="dossier.expert_adverse" :disabled="isDisabled">
                                    </v-select>
                                </div>
                            </div>

                            <!--<div class="two fields">
                                <div class="field">
                                    <v-select class="formInput" placeholder="NATURE"
                                              :options="['NORMALE', 'AEG','ARB','EC','TRPI','APPR','PVC','COLLIGIALE','ESTIMATIF']"
                                              v-model="dossier.nature2" :disabled="isDisabled">
                                    </v-select>
                                </div>

                                <div class="field" v-if="(dossier.courtier && dossier.courtier.label === 'SIEGE' && dossier.nature2 === 'EC')
                                    || dossier.nature2 === 'APPR' || dossier.nature2 === 'COLLIGIALE'">
                                    <v-select class="formInput" placeholder="EXPERT ADVERSE"
                                              :options="expert_adverse_list" :reduce="expert => expert.label"
                                              v-model="dossier.expert_adverse" :disabled="isDisabled">
                                    </v-select>
                                </div>
                            </div>-->

                            <div class="three fields">
                                <div class="field">
                                    <input type="text" placeholder="Nom de réparateur" class="formInput"
                                           id="nom_reparateur" v-model.number="dossier.nom_reparateur"
                                           :disabled="isDisabled">
                                </div>

                                <div class="field">
                                    <input type="number" min="0" placeholder="Montant devis" class="formInput"
                                           id="montant_devis" v-model.number="dossier.montant_devis"
                                           :disabled="isDisabled">
                                </div>

                                <div class="field">
                                    <input type="number" min="0" placeholder="Montant de la facture" class="formInput"
                                           id="montant_facture" v-model.number="dossier.montant_facture"
                                           :disabled="isDisabled">
                                </div>

                            </div>

                            <div class="two fields">
                                <div class="field">
                                    <!--<label class="formLabel">Type dossier</label>-->
                                    <v-select class="formInput" placeholder="TYPE DOSSIER"
                                              style="text-transform: uppercase"
                                              :options="['reforme', 'reparation']" v-model="dossier.type_dossier"
                                              :disabled="isDisabled">
                                    </v-select>
                                </div>

                                <div class="field" v-if="dossier.type_dossier==='reforme'">
                                    <!--<label class="formLabel">TYPE REFORME</label>-->
                                    <v-select class="formInput" placeholder="TYPE REFORME"
                                              style="text-transform: uppercase"
                                              :options="['economique', 'technique']" v-model="dossier.type_reforme"
                                              :disabled="isDisabled">
                                    </v-select>
                                </div>
                            </div>
                            <div class="ui clearing segment segment-style">
                                <div class="two fields">
                                    <div class="field">
                                        <div class="ui radio checkbox">
                                            <input type="radio" name="type_reference" value="normale" :disabled="isEdit"
                                                   v-model="dossier.type_reference">
                                            <label>RÉFÉRENCE NORMALE</label>
                                        </div>
                                    </div>

                                    <div class="field">
                                        <div class="ui radio checkbox ">
                                            <input type="radio" name="type_reference" value="croise" :disabled="isEdit"
                                                   v-model="dossier.type_reference">
                                            <label>RÉFÉRENCE CROISÉE</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!--Vehicule tab-->
                    <assure :dossier_prop="dossier" :marque_list_prop="marque_list" :isEdit="isEdit"
                            :isDisabled="isDisabled" v-else-if="currentTab==='VehiculeTab'"/>

                    <!--Adverse tab-->
                    <adverse :adverse_prop="dossier.adverse" :assurance_list_prop="assurance_list" :adverse_key="'adverse'"
                             :marque_list_prop="marque_list" :isDisabled="isDisabled" :id_doc="dossier._id" :type_reference="dossier.type_reference"
                             v-if="currentTab==='AdverseTab'" :key="1"/>

                    <!-- Second Adverse tab-->
                    <adverse :adverse_prop="dossier.adverse_second" :assurance_list_prop="assurance_list" :adverse_key="'adverse_second'"
                             :marque_list_prop="marque_list" :isDisabled="isDisabled" :id_doc="dossier._id"
                             v-else-if="currentTab==='AdverseSecondTab'" :key="2"/>


                    <!--Estimation tab-->
                    <div class="ui accordion" id="Estimation_form" v-else-if="currentTab==='EstimationTab'">
                        <template v-if="dossier.categorie === 'SYSTEME'">
                            <div class="tabTitleContainer title">
                                <div class="tabTitle">TOTAL NET</div>
                                <div class="line"></div>
                                <i class="large angle right icon"></i>
                            </div>
                            <div class="content p-half">
                                <div class="ui form p-1">
                                    <div class="field">
                                        <input type="number" class="formInput" placeholder="TOTAL NET"
                                               v-model="dossier.total_net" :disabled="isDisabled">
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <!-- Estimation -->
                            <div class="tabTitleContainer title">
                                <div class="tabTitle">Estimation</div>
                                <div class="line"></div>
                                <i class="large angle right icon"></i>
                            </div>
                            <div class="content p-half">
                                <div class="ui form p-1">
                                    <template v-if="dossier.type_dossier === 'reparation'">
                                        <div class="three fields">
                                            <div class="field" style="position: relative;">
                                                <input type="number" min="0" placeholder="FOURNITURES" class="formInput"
                                                       :style="dossier.estimation.fourniture? 'padding-left: 62px': ''"
                                                       id="fourniture" v-model.number="dossier.estimation.fourniture"
                                                       :disabled="isDisabled">
                                                <label for="fourniture" class="static-label" v-if="dossier.estimation.fourniture">FOUR: </label>
                                            </div>

                                            <div class="field" style="position: relative;">
                                                <!--<label class="formLabel">vétusté</label>-->
                                                <input type="number" min="0" maxlength="3" class="formInput" id="vetuste"
                                                       placeholder="VÉTUSTÉ" :style="dossier.estimation.vetuste ? 'padding-left: 88px': ''"
                                                       v-model.number="dossier.estimation.vetuste" :disabled="isDisabled"/>
                                                <label for="vetuste" class="static-label" v-if="dossier.estimation.vetuste">VÉTUSTÉ: </label>
                                            </div>

                                            <div class="field">
                                                <div class="ui right labeled input">
                                                    <input type="number" min="0" max="100" maxlength="3"
                                                           class="formInput" id="vetuste%" placeholder="VÉTUSTÉ %"
                                                           :style="dossier.estimation.vetuste_percentage ? 'padding-left: 116px': ''"
                                                           v-model.number="dossier.estimation.vetuste_percentage"
                                                           :disabled="isDisabled"/>
                                                    <label for="vetuste%" class="static-label" v-if="dossier.estimation.vetuste_percentage">VÉTUSTÉ % : </label>
                                                    <div class="ui basic label"
                                                         style="background: #E4F0F4;color: var(--main-color-blue)">
                                                        %
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="three fields">
                                            <div class="field" style="position: relative;">
                                                <!--<label class="formLabel">Main d’œuvre tôlerie</label>-->
                                                <input type="number" min="0" placeholder="MAIN D’ŒUVRE TÔLERIE"
                                                       class="formInput" id="md_tolerie"
                                                       :style="dossier.estimation.md_tolerie ? 'padding-left: 118px': ''"
                                                       v-model.number="dossier.estimation.md_tolerie"
                                                       :disabled="isDisabled">
                                                <label for="md_tolerie" class="static-label" v-if="dossier.estimation.md_tolerie">MD. TÔLERIE : </label>
                                            </div>

                                            <div class="field" style="position: relative;">
                                                <!--<label class="formLabel">Main d’œuvre peinture</label>-->
                                                <input type="number" min="0" class="formInput" placeholder="MAIN D’ŒUVRE PEINTURE"
                                                       :style="dossier.estimation.md_peinture ? 'padding-left: 132px': ''"
                                                       v-model.number="dossier.estimation.md_peinture" id="md_peinture"
                                                       :disabled="isDisabled"/>
                                                <label for="md_tolerie" class="static-label" v-if="dossier.estimation.md_peinture">MD. PEINTURE : </label>
                                            </div>

                                            <div class="field" style="position: relative;">
                                                <!--<label class="formLabel">Main d’œuvre mécanique</label>-->
                                                <input type="number" min="0" class="formInput"
                                                       placeholder="MAIN D’ŒUVRE MÉCANIQUE" id="md_mecanique"
                                                       :style="dossier.estimation.md_mecanique ? 'padding-left: 150px': ''"
                                                       v-model.number="dossier.estimation.md_mecanique"
                                                       :disabled="isDisabled"/>
                                                <label for="md_mecanique" class="static-label" v-if="dossier.estimation.md_mecanique">MD. MÉCANIQUE : </label>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="dossier.type_dossier === 'reforme'">
                                        <div class="three fields">
                                            <div class="field" style="position: relative">
                                                <!--<label class="formLabel">Valeur vénale</label>-->
                                                <input type="number" min="0" class="formInput"
                                                       placeholder="VALEUR VÉNALE" id="v_venale"
                                                       :style="dossier.estimation.valeur_venale ? 'padding-left: 102px': ''"
                                                       v-model.number="dossier.estimation.valeur_venale"
                                                       @change="bindVenalEpave" :disabled="isDisabled"/>
                                                <label for="v_venale" class="static-label" v-if="dossier.estimation.valeur_venale">V. VÉNALE : </label>
                                            </div>

                                            <div class="field" style="position: relative">
                                                <!--<label class="formLabel">Valeur épave</label>-->
                                                <input type="number" min="0" class="formInput"
                                                       placeholder="VALEUR ÉPAVE" id="v_epave"
                                                       :style="dossier.estimation.valeur_epave ? 'padding-left: 94px': ''"
                                                       v-model.number="dossier.estimation.valeur_epave"
                                                       :disabled="isDisabled" @change="bindVenalEpave"/>
                                                <label for="v_epave" class="static-label" v-if="dossier.estimation.valeur_epave">V. ÉPAVE : </label>
                                            </div>

                                            <div class="field" style="position: relative;">
                                                <!--<label class="formLabel">Différence des valeurs</label>-->
                                                <input type="number" class="formInput" id="diff_valeur"
                                                       placeholder="DIFFÉRENCE DES VALEURS" :value="difference"
                                                       :style="difference ? 'padding-left: 128px': ''"
                                                       readonly/>
                                                <label for="diff_valeur" class="static-label" v-if="difference">DIFF DES VAL : </label>
                                            </div>
                                        </div>

                                        <div class="two fields">
                                            <div class="field" style="position: relative;">
                                                <!--<label class="formLabel" style="font-size: smaller">Valeur à neuf au jour du
                                                    sinistre</label>-->
                                                <input type="number" min="0" class="formInput"
                                                       placeholder="VALEUR À NEUF AU JOUR DU SINISTRE" id="v_sinistre"
                                                       :style="dossier.estimation.valeur_sinistre ? 'padding-left: 96px': ''"
                                                       v-model.number="dossier.estimation.valeur_sinistre"
                                                       :disabled="isDisabled"/>
                                                <label for="v_sinistre" class="static-label" v-if="dossier.estimation.valeur_sinistre">V. À NEUF  : </label>
                                            </div>

                                            <div class="field" style="position: relative">
                                                <!--<label class="formLabel">Estimation de la réparation</label>-->
                                                <input type="number" min="0" class="formInput"
                                                       placeholder="ESTIMATION DE LA RÉPARATION" id="estimation_rep"
                                                       :style="dossier.estimation.reparation ? 'padding-left: 153px': ''"
                                                       v-model.number="dossier.estimation.reparation"
                                                       :disabled="isDisabled"/>
                                                <label for="estimation_rep" class="static-label" v-if="dossier.estimation.reparation">ESTIM DE LA RÉP : </label>
                                            </div>

                                        </div>


                                    </template>


                                </div>
                            </div>
                            <template v-if="dossier.type_dossier === 'reparation'">
                                <!-- Detail fourniture -->
                                <div class="tabTitleContainer title">
                                    <div class="tabTitle">Détail fourniture</div>
                                    <div class="line"></div>
                                    <i class="large angle right icon"></i>
                                </div>
                                <div class="content p-half">
                                    <div class="ui form p-1"
                                         style="padding-left: 0 !important;">
                                        <detail_fourniture :items="dossier.detail_fourniture" :isDisabled="isDisabled"
                                                           :total_fourniture_prop="dossier.total_fourniture"/>
                                    </div>
                                </div>
                                <!-- Detail main d'oeuvre -->
                                <div class="tabTitleContainer title">
                                    <div class="tabTitle">Détail main d'œuvre</div>
                                    <div class="line"></div>
                                    <i class="large angle right icon"></i>
                                </div>
                                <div class="content p-half">
                                    <div class="ui form p-1" style="padding-left: 0 !important;">
                                        <Detail_main_oeuvre :items="dossier.detail_main_oeuvre" :isDisabled="isDisabled"
                                                            :total_main_ouvre_prop="dossier.total_main_ouvre"/>
                                    </div>
                                </div>
                            </template>

                            <!-- Autre -->
                            <!--<template v-if="dossier.type_dossier ==='reparation'">-->

                                <div class="tabTitleContainer title">
                                    <div class="tabTitle">Autre</div>
                                    <div class="line"></div>
                                    <i class="large angle right icon"></i>
                                </div>
                                <div class="content p-half">
                                    <div class="ui form p-1">
                                        <div class="four fields" v-if="dossier.type_dossier ==='reparation'">

                                            <div class="field">
                                                <!--<label>Immobilisation</label>-->
                                                <input type="number" class="formInput"
                                                       v-model.number="dossier.finances.immobilisation"
                                                       placeholder="IMMOBILISATION" :disabled="isDisabled">
                                            </div>
                                            <div class="field">
                                                <!--<label>Dépannage</label>-->
                                                <input type="number" class="formInput"
                                                       v-model.number="dossier.finances.depannage"
                                                       placeholder="DEPANNAGE" :disabled="isDisabled">
                                            </div>

                                            <div class="field">
                                                <!--<label>Franchise</label>-->
                                                <input type="number" class="formInput"
                                                       v-model.number="dossier.finances.franchise"
                                                       placeholder="FRANCHISE" :disabled="isDisabled">
                                            </div>

                                            <div class="field">
                                                <!--<label>autres</label>-->
                                                <input type="number" class="formInput"
                                                       v-model.number="dossier.finances.autres"
                                                       placeholder="AUTRES" :disabled="isDisabled">
                                            </div>

                                        </div>

                                        <div class="field" style="position: relative">
                                            <input type="text" class="formInput" :value="age_vehicule" id="age_vehicule"
                                                   :style="!is_age_vehicule_null ? 'padding-left: 139px': ''"
                                                   placeholder="AGE VEHICULE" disabled>
                                            <label for="age_vehicule" class="static-label"
                                                   v-if="!is_age_vehicule_null">AGE VEHICULE: </label>
                                        </div>
                                    </div>
                                </div>

                            <!--</template>-->

                            <!-- Immobilisation et Dépannage -->
                            <template v-if="dossier.type_dossier === 'reforme'">
                                <div class="tabTitleContainer title">
                                    <div class="tabTitle">Réforme</div>
                                    <div class="line"></div>
                                    <i class="large angle right icon"></i>
                                </div>
                                <div class="content p-half">
                                    <div class="ui form p-1">
                                        <div class="four fields">
                                            <div class="field" style="position: relative;">
                                                <!--<label>Valeur assurée</label>-->
                                                <input type="number" class="formInput"
                                                       v-model.number="dossier.reforme.assuree" id="v_assuree"
                                                       :style="dossier.reforme.assuree ? 'padding-left: 113px': ''"
                                                       placeholder="VALEUR ASSURÉE" :disabled="isDisabled">
                                                <label for="v_assuree" class="static-label" v-if="dossier.reforme.assuree">V. ASSURÉE : </label>
                                            </div>
                                            <div class="field" style="position: relative;">
                                                <!--<label>Valeur à neuf</label>-->
                                                <input type="number" class="formInput"
                                                       v-model.number="dossier.reforme.neuf" id="v_neuf"
                                                       :style="dossier.reforme.neuf ? 'padding-left: 101px': ''"
                                                       placeholder="VALEUR A NEUF" :disabled="isDisabled">
                                                <label for="v_neuf" class="static-label" v-if="dossier.reforme.neuf">V. A NEUF : </label>
                                            </div>

                                            <div class="field" style="position: relative;">
                                                <!--<label>Valeur vénale</label>-->
                                                <input type="number" class="formInput"
                                                       v-model.number="dossier.reforme.venale" id="v_venale_refome"
                                                       :style="dossier.reforme.venale ? 'padding-left: 102px': ''"
                                                       placeholder="VALEUR VENALE" :disabled="isDisabled"
                                                       @change="reformeVenalChanged">
                                                <label for="v_venale_refome" class="static-label" v-if="dossier.reforme.venale">V. VENALE : </label>
                                            </div>
                                            <div class="field" style="position: relative;">
                                                <!--<label>Valeur épave</label>-->
                                                <input type="number" class="formInput"
                                                       v-model.number="dossier.reforme.epave" id="v_epave_reforme"
                                                       :style="dossier.reforme.epave ? 'padding-left: 92px': ''"
                                                       placeholder="VALEUR EPAVE" :disabled="isDisabled"
                                                       @change="reformeEpaveChanged">
                                                <label for="v_epave_reforme" class="static-label" v-if="dossier.reforme.epave">V. EPAVE : </label>
                                            </div>
                                        </div>

                                        <detail_valeur_neuf :items="dossier.detail_valeur_neuf"
                                                            :isDisabled="isDisabled"/>
                                    </div>

                                </div>
                            </template>

                            <!-- Honoraire -->

                            <div class="tabTitleContainer title">
                                <div class="tabTitle">Honoraire</div>
                                <div class="line"></div>
                                <i class="large angle right icon"></i>
                            </div>
                            <div class="content p-half">
                                <div class="ui form p-1">

                                    <div class="field">
                                        <!--<label>Honoraires</label>-->
                                        <input type="number" class="formInput"
                                               v-model.number="dossier.finances.honoraires"
                                               placeholder="HONORAIRES">
                                    </div>

                                </div>
                            </div>


                        </template>
                    </div>

                </div>
                <div class="six wide column" v-if="isEdit">
                    <h2 class="downloadTab">
                        Documents à télécharger
                        <span class="right floated flex items-end" @click="$modal.show('upload-modal')">
                            <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clip-path="url(#clip0)">
                                    <path
                                            d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                            fill="#0953AA"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <path fill="#fff" d="M0 0h20v20H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            Ajouter
                        </span>
                    </h2>
                    <div class="ui accordion">
                        <div class="tabTitleContainer active title">
                            <div class="tabTitle">Fond de dossier</div>
                            <div class="line"></div>
                            <i class="large angle right icon"></i>
                        </div>
                        <div class="active content p-half">
                            <pieces-joints :dossier="dossier" :attachment_prop="attachment_list"
                                           @updateCompleted="getAttachmentList"/>

                        </div>


                        <div class="tabTitleContainer title">
                            <div class="tabTitle">Documents générés</div>
                            <div class="line"></div>
                            <i class="large angle right icon"></i>
                        </div>
                        <div class="content p-half">
                            <generatedFilesTable :attachment_prop="attachment_list"/>
                        </div>

                        <div class="tabTitleContainer title">
                            <div class="tabTitle">Historique des revus</div>
                            <div class="line"></div>
                            <i class="large angle right icon"></i>
                        </div>
                        <div class="content p-half">
                            <revus-history :id_dossier="dossier._id" />
                        </div>

                    </div>

                </div>
            </div>
            <div class="row centered" :style="isEdit ? 'margin-top: 1.5em' : ''">
                <div class="eight wide column">
                    <div class="ui form p-1">

                        <div class="field" v-if="!isEdit">
                            <label class="formLabel">
                                <h3>Observations <span class="right floated add-icon-label">
                                    <svg viewBox="0 0 20 20" class="add-icon" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path
                                                    d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                                    fill="#0953AA"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <path fill="#fff" d="M0 0h20v20H0z"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Ajouter commentaire
                                </span></h3>
                            </label>
                            <textarea v-model="message" class="formInput" id="" rows="3"
                                      placeholder="Observations / Commentaire" :disabled="isEdit"></textarea>
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <!-- confirmation duplique modal -->
        <confirmation-modal message="Vous voulez vraiement dupliquer ce dossier?" :key="1"
                            :isBusy="isBusy" @actionConfirmed="duplicateDossier" />

        <revue_modal @confirmed="updateDocTaskStatus"/>

    </div>
</template>

<script>
    import navbar from "@/components/navbar.vue";
    import infoSidebar from "@/components/infoSidebar.vue";
    import FolderInfo from "@/components/folder-info.vue";
    import FolderTasks from "@/components/folder-tasks.vue";
    import FolderInterventions from "@/components/folder-interventions.vue";
    import FolderComments from "@/components/folder-comments.vue";
    import ImageTerrain from "@/components/image-terrain";
    import piecesJoints from "@/components/document/pieces-joints.vue";
    import dateMilli from "@/components/dateMilli.vue";
    import generatedFilesTable from "@/components/document/generated_files_table.vue";
    import historyImages from "@/components/document/history_images";
    import Detail_fourniture from "@/components/element_dossier/detail_fourniture";
    import Detail_main_oeuvre from "@/components/element_dossier/detail_main_oeuvre";
    import detail_valeur_neuf from "@/components/element_dossier/detail_valeur_neuf";
    import confirmCreateDossier from "@/components/modals/confirm-create-dossier";
    import assure from "@/components/vehicle/assure";
    import adverse from "@/components/vehicle/adverse";
    import revusHistory from "@/components/document/revusHistory.vue";
    import confirmationModal from "@/components/modals/dynamic_modal";
    import revue_modal from "@/components/modals/revue_modal.vue";

    //templates
    import prise_rdv from "@/components/templates/prise_rdv.vue";
    import valider_rdv from "@/components/templates/valider_rdv.vue";
    import dispatcher from "@/components/templates/dispatcher.vue";
    import cloture from "@/components/templates/cloture.vue";
    import generate_report from "@/components/templates/generate_report.vue";
    import validation_task from "@/components/templates/validation_task.vue";
    import etablir_avis_reforme from "@/components/templates/etablir_avis_reforme.vue";
    import etablir_forfait from "@/components/templates/etablir_forfait.vue";
    import rapport_preleminaire from "@/components/templates/rapport_preleminaire.vue";
    import fiche_instruction from "@/components/templates/fiche_instruction.vue";
    import instruire_dossier from "@/components/templates/instruire_dossier.vue";
    import avis_dommages from "@/components/templates/avis_dommages.vue";
    import etablir_rapport_expertise from "@/components/templates/etablir_rapport_expertise.vue";


    import 'viewerjs/dist/viewer.css'
    import SockJS from "sockjs-client";


    export default {
        name: "folder",
        components: {
            assure,
            adverse,
            confirmCreateDossier,
            ImageTerrain,
            Detail_fourniture,
            navbar,
            infoSidebar,
            FolderInfo,
            FolderTasks,
            FolderInterventions,
            FolderComments,
            piecesJoints,
            prise_rdv,
            valider_rdv,
            dispatcher,
            dateMilli,
            cloture,
            generate_report,
            generatedFilesTable,
            validation_task,
            etablir_avis_reforme,
            etablir_forfait,
            rapport_preleminaire,
            fiche_instruction,
            instruire_dossier,
            Detail_main_oeuvre,
            avis_dommages,
            etablir_rapport_expertise,
            detail_valeur_neuf,
            historyImages,
            confirmationModal,
            revue_modal,
            revusHistory
        },
        props: {
            dossier_prop: {type: Object},
            task: {type: Object}
        },
        computed: {
            current_user() {
                return this.$store.state.current_user;
            },
            difference(){
                if(typeof this.dossier.estimation.valeur_venale !== undefined && typeof this.dossier.estimation.valeur_epave !== undefined){
                    return this.dossier.estimation.valeur_venale - this.dossier.estimation.valeur_epave;
                }
                return null;
            },
            cadre_expertise(){
                return _.sortBy(this.$store.state.cadre_expertise)
            },
            age_vehicule(){
                if(this.dossier.vehicule.dmc && this.dossier.assurance.date_sinistre){
                    let dmc = this.$moment(this.dossier.vehicule.dmc);
                    let date_sinistre = this.$moment(this.dossier.assurance.date_sinistre);
                    let age = this.$moment.duration(dmc.diff(date_sinistre));

                    let years = age.years();
                    let months  = age.months();
                    let days = age.days();

                    let result = years + " ans, " + months + " mois, " + days + " jours";
                    this.dossier.vehicule.age_vehicule = {days, months, years};
                    this.is_age_vehicule_null = false;
                    return result
                }
                else{
                    this.is_age_vehicule_null = true;
                    return null
                }
            }
        },
        data() {
            return {
                isBusy: false,
                isEdit: false,
                isDisabled: false,
                bind_venal: false,
                bind_epave: false,
                is_suspended: false,
                showDatePaiement: false,
                suspended_tasks: [],
                is_age_vehicule_null: false,
                currentTab: "AssuranceTab",
                dossier: {
                    assurance: null,
                    courtier: null,
                    vehicule: {
                        marque: null,
                        model: null,
                        n_immat: '',
                        proprietaire: null
                    },
                    propri: {
                        nom: '',
                        prenom: '',
                        email: null,
                        tel: '',
                        adresse: null
                    },
                    adverse: {
                        nom: null,
                        prenom: null,
                        email: null,
                        tel: null,
                        adresse: null,
                        assurance: null,
                        courtier: null,
                        vehicule: {}
                    },
                    adverse_second: {
                        nom: null,
                        prenom: null,
                        email: null,
                        tel: null,
                        adresse: null,
                        assurance: null,
                        courtier: null,
                        vehicule: {}
                    },
                    estimation: {
                    },
                    detail_fourniture: [],
                    detail_main_oeuvre: [],
                    reforme: {},
                    detail_valeur_neuf: [],
                    finances: {},
                    points_chock: [],
                    nature: '',
                    total_fourniture: {
                        total_vetuste: 0,
                        total_montant: 0
                    },
                    total_main_ouvre:{
                        total_montant: 0
                    },
                    total_net: 0,
                    categorie: '',
                    type_reference: 'normale'
                },
                message: '',
                marque_list: [],
                model_list: [],
                assurance_list: [],
                attachment_list: [],
                dossier_tasks: [],
                generated_files: [],
                courtier_list: [],
                expert_adverse_list: []
            };
        },
        methods: {
            save() {
                log('Save - isEdit:', this.isEdit);
                if (!this.dossier.assurance._id) {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: 'Compagnie est obligatoire!'
                    });
                    return;
                }
                if(this.dossier.categorie.toLowerCase() !== 'systeme'){
                    this.dossier.total_net = this.calculeTotalNet()
                }
                if (this.isEdit) {
                    this.updateDossier()
                } else {
                    // openModal
                    this.$modal.show('confirm-create-dossier');
                }
            },
            calculeTotalNet(){
                let result=0;
                if (this.dossier.type_dossier === 'reparation') {
                    let total_fourniture = this.dossier.total_fourniture.total_montant ? this.dossier.total_fourniture.total_montant : 0;
                    let total_main_ouvre = this.dossier.total_main_ouvre.total_montant ? this.dossier.total_main_ouvre.total_montant : 0;
                    let franchise = this.dossier.finances.franchise ? this.dossier.finances.franchise : 0;
                    let autre = this.dossier.finances.autres ? this.dossier.finances.autres : 0;
                    result = (total_fourniture + total_main_ouvre) - franchise - autre

                    return result
                }
                else if(this.dossier.type_dossier === 'reforme'){
                    let venal = this.dossier.reforme.venale ? this.dossier.reforme.venale : 0
                    let epave = this.dossier.reforme.epave ? this.dossier.reforme.epave : 0
                    result = venal - epave;
                }
                return result
            },
            createDossier(selected_dispatcher) {
                console.log('createDossier');
                this.isBusy = true;
                this.dossier.dispatcher = selected_dispatcher
                let query = {
                    dossier: this.dossier,
                    message: this.message,
                    task: this.$store.getters.findOneAction({_id: 'prise_rdv'})
                };
                this.$store.dispatchAsync(this.$SHARED.services.document.create, query).then(data => {
                    this.$notify({
                        group: 'user-message', type: 'success',
                        text: this.$SHARED.messages.document.creation_succeeded
                    });
                    this.$router.push({name: 'home'});
                    this.isBusy = false;
                }, code => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages[code]});
                });
            },
            updateDossier() {
                console.log('updateDossier');
                this.isBusy = true;
                let dossier = JSON.parse(JSON.stringify(this.dossier))
                delete dossier.status;

                let update_body = {
                    collection: this.$SHARED.collections.documents,
                    query: {_id: this.dossier._id},
                    update: {$set: dossier},
                    remove_id: true
                };
                this.$store.requestUpdate(update_body).then(data => {
                    this.isBusy = false;
                    this.$notify({
                        group: 'user-message', type: 'success',
                        text: this.$SHARED.messages.document.update_succeeded
                    });
                }, code => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'warn', text: this.$SHARED.messages[code]});
                });
            },
            duplicateDossier(){
                console.log('duplicateDossier');
                this.isBusy = true;
                let body = {
                    task: this.$store.getters.findOneAction({_id:'dispatcher'}),
                    id_doc: this.dossier_prop._id
                }
                this.$store.dispatchAsync(this.$SHARED.services.document.duplicateDocument, body).then(data => {
                    this.$modal.hide("dynamic-modal");
                    this.isBusy = false;
                    this.$notify({
                        group: 'user-message', type: 'success',
                        text: this.$SHARED.messages.document.creation_succeeded
                    });
                    this.$router.push({name: 'home'})
                }, code => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'warn', text: this.$SHARED.messages[code]});
                });
            },
            updateDocTaskStatus(message){
                console.log('updateDocTaskStatus');
                this.isBusy = true;

                let update_body = {
                    task_id: this.suspended_tasks[0]._id,
                    id_doc: this.dossier_prop._id,
                    message
                };
                this.$store.dispatchAsync(this.$SHARED.services.document.updateStatus, update_body).then(data => {
                    this.isBusy = false;
                    this.$notify({
                        group: 'user-message', type: 'success',
                        text: this.$SHARED.messages.document.update_succeeded
                    });
                    this.$router.push({name: 'home'})
                }, code => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'warn', text: this.$SHARED.messages[code]});
                });
            },
            getHonoraire(){
                log('getHonoraire');
                let match = {};
                if(this.dossier.assurance._id && this.dossier.nature){
                    match.id_compagnie = this.dossier.assurance._id;
                    match.cadre_expertise = this.dossier.nature;
                }
                const data = {
                    collection: this.$SHARED.collections.honoraire,
                    query: match,
                    concat_pipeline: [
                        {
                            $lookup: {
                                from: "assurance",
                                localField: "id_compagnie",
                                foreignField: "_id",
                                as: "assurance"
                            }
                        }
                    ],
                    options: {page: 1, limit: -1}
                };
                this.$store.requestFind(data).then(ar => {
                    if(!_.isEmpty(ar.docs)){
                        this.dossier.finances.honoraires = ar.docs[0].montant;
                    }
                }, code => {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getMarqueList() {
                log('getMarques');
                const data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {type: 'marque'},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.marque_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getModels() {
                log('getModels');
                let marque_id = this.dossier.vehicule.marque ? this.dossier.vehicule.marque._id : '';

                if (this.currentTab === 'AdverseTab') {
                    marque_id = this.dossier.vehicule_adverse.marque._id;
                }

                const data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {type: 'model', id_pere: marque_id},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.model_list = ar.docs;
                }, code => {
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});
                })
            },
            getAssurancesList() {
                log('getAssurancesList');
                const data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {},
                    options: {
                        page: 1, limit: -1,
                        sort: {label: 1}
                    }
                };
                this.$store.requestFind(data).then(ar => {
                    this.assurance_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getCourtierList() {
                log('getCourtierList');
                if (!this.dossier.assurance || !this.dossier.assurance._id)
                    return;
                const data = {
                    collection: this.$SHARED.collections.courtier,
                    query: {
                        assurance: this.dossier.assurance._id
                    },
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.courtier_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getAttachmentList() {
                log('getAttachmentList');
                const data = {
                    collection: this.$SHARED.collections.attachment,
                    query: {
                        id_doc: this.dossier._id
                    },
                    options: {page: 1, limit: -1, sort: {date_creation: -1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.attachment_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            getExpertAdverse() {
                log('getExpertAdverse');
                const data = {
                    collection: this.$SHARED.collections.expert_adverse,
                    query: {},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.expert_adverse_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            task_completed() {
                log('task_completed');
                this.task.action.task_status = 'complete'
                this.$router.push({name: 'home'})
                //this.getDossierTasks();
            },
            dossier_payed() {
                log('dossier_payed');
                if(!this.dossier.date_paiement){
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: "La date de paiement est obligatoire!"
                    });
                    return
                }

                this.isBusy = true;

                let update_body = {
                    collection: this.$SHARED.collections.documents,
                    query: {_id: this.dossier._id},
                    update: {$set: {status: 'paye'}}
                };

                this.$store.requestUpdate(update_body).then(data => {
                    this.isBusy = false;
                    this.$notify({
                        group: 'user-message', type: 'success',
                        text: this.$SHARED.messages.document.update_succeeded
                    });
                    this.$router.push({name: 'home'})
                }, code => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'warn', text: this.$SHARED.messages[code]});
                });
            },
            confirmationActivateCloture(){
                this.$modal.show('dialog', {
                    title: 'Attention!',
                    text: 'Vous voulez vraiement réactiver ce dossier?',
                    buttons: [
                        {
                            title: '<h4 style="color: #808080">Non</h4>',
                            default: true, // gets clicked by default if you pushed enter
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: '<h4 style="color: red">Oui</h4>',
                            handler: () => {
                                this.activateCloture();
                                this.$modal.hide('dialog')
                            }
                        }
                    ]
                })
            },
            activateCloture(){
                log('activateCloture');
                if(this.isBusy) return;

                this.isBusy = true;
                let body = {
                    id_doc: this.dossier._id,
                    dispatcher: this.dossier.dispatcher
                }
                this.$store.dispatchAsync(this.$SHARED.services.document.activateDocument, body).then(data => {
                    this.isBusy = false;
                    this.$modal.hide("dynamic-modal");
                    this.$notify({
                        group: 'user-message', type: 'success',
                        text: this.$SHARED.messages.document.creation_succeeded
                    });
                    this.$router.push({name: 'home'})
                }, code => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'warn', text: this.$SHARED.messages[code]});
                });
            },
            getDossierTasks() {
                log('getDossierTasks');
                const data = {
                    collection: this.$SHARED.collections.documents,
                    query: {type: 'link_task', id_doc: this.dossier._id},
                    options: {sort: {date_creation: -1}, page: 1, limit: -1}
                };
                this.$store.requestFind(data).then(ar => {
                    this.dossier_tasks = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            markAsSeen() {
                if (!this.task.seen && this.task.receiver._id === this.current_user._id) {
                    console.warn('will mark as seen');
                    this.$store.dispatchAsync(this.$SHARED.services.task.seen, {_id: this.task._id}).then(data => {
                        this.$notify({group: 'user-message', type: 'info', text: 'marquer comme lu!'});
                    })
                }
            },
            startTaskTimer() {
                console.log('startTaskTimer');
                this.sock = new SockJS(this.$store.state.SERVER_ADDRESS + '/task_timer');

                this.sock.onopen = () => {
                    console.warn('connection opened');
                    let query = JSON.stringify({task_id: this.task._id});
                    this.timer_interval = setInterval(() => {
                        log("recorded one minute");
                        this.task.timer += 1;
                        this.sock.send(query);
                    }, 60000);
                };

                this.sock.onclose = () => {
                    console.warn('timer connection closed');
                    clearInterval(this.timer_interval);
                };
            },
            stopTaskTimer() {
                console.log('stopTaskTimer');
                if (this.sock) {
                    clearInterval(this.timer_interval);
                    this.sock.close();
                }
            },
            bindVenalEpave() {
                if (!this.dossier.reforme.venale) {
                    this.bind_venal = true
                }
                if (!this.dossier.reforme.epave) {
                    this.bind_epave = true
                }
                if (this.bind_venal) {
                    this.dossier.reforme.venale = this.dossier.estimation.valeur_venale
                }
                if (this.bind_epave) {
                    this.dossier.reforme.epave = this.dossier.estimation.valeur_epave
                }
            },
            reformeVenalChanged() {
                if (this.dossier.reforme.venal !== '') {
                    this.bind_venal = false
                }
            },
            reformeEpaveChanged() {
                if (this.dossier.reforme.epave !== '') {
                    this.bind_epave = false
                }
            },
            getSuspendedTask(){
                const data = {
                    collection: this.$SHARED.collections.documents,
                    query: {
                        type: 'link_task',
                        id_doc: this.dossier._id,
                        'action.task_status': 'suspended'
                    },
                    options: {page: 1, limit: -1}
                };
                this.$store.requestFind(data).then(ar => {
                    if(ar.count > 0){
                        this.suspended_tasks = ar.docs;
                        this.is_suspended = true
                    }
                }, code => {
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});
                })
            },
        },
        updated() {
            $('.ui.accordion').accordion({exclusive: true});
        },
        mounted() {
            $('.ui.accordion').accordion({exclusive: true});
            this.getAssurancesList();
            this.getMarqueList();
            this.getExpertAdverse();
            if (this.dossier_prop) {
                console.log('isEDDDIIIT')
                //_.assign(this.dossier, this.dossier_prop);
                this.dossier = Object.assign({}, this.dossier, JSON.parse(JSON.stringify(this.dossier_prop)));
                //this.dossier = this.dossier_prop;
                this.isEdit = true;
                this.isDisabled = true;
                this.getCourtierList();
                this.getDossierTasks();
                this.getAttachmentList();
                //this.markAsSeen();
                if (this.task && (this.task.receiver._id === this.current_user._id)) {
                    this.startTaskTimer();
                }
            }
            if(this.dossier_prop && !this.task){
                this.getSuspendedTask();
            }
        },
        beforeDestroy() {
            this.stopTaskTimer();
        }
    };
</script>
<style scoped>
    .ui.accordion .active.title .angle.right,
    .ui.accordion .accordion .active.title .angle.right {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .ui.accordion.menu .item .active.title > .angle.right {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .form-label {
        width: 5% !important;
        font-size: 25px !important;
        text-align: center !important;
        padding: 0 !important;
        line-height: 45px !important;
        background-color: #E4F0F4 !important;
    }

    .check-on-image {
        position: absolute;
        z-index: 2;
    }

    .carblock {
        position: relative;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .static-value {
        position: absolute;
        left: 20px;
        font-weight: bold !important;
        font-size: 1em !important;
        color: var(--main-color-blue) !important;
        top: 33px;
    }

    .static-label {
        position: absolute;
        left: 20px;
        font-weight: bold !important;
        font-size: 1em !important;
        color: var(--main-color-blue) !important;
        top: 9px;
    }

    .dossier-status{
        display: inline-flex !important;
        align-items: center !important;
    }
    .dossier-status .status-icon{
        font-size: 1.7em !important;
        padding-right: 8px !important;
    }
    .segment-style{
        background-color: #F5F5F5;
        font-size: 1em !important;
        font-weight: bold;
    }
    .edit-paiement{
        font-size: 7px;
        position: relative;
        top: -12px;
        left: 10px;
        cursor: pointer;
    }
    .pl-1-5{
        padding-left: 1.5em;
    }
</style>
