<template>
    <table class="ui very basic striped table">
        <thead>
        <tr class="center aligned">
            <th class="five wide">Année</th>
            <th class="three wide">Valeur</th>
            <th class="one wide">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in working_items" class="center aligned">
            <td>{{item.annee}}</td>
            <td>{{item.valeur}}</td>
            <td :class="{'disabled': isDisabled}">
                <i class="x red icon" style="cursor: pointer" title="Supprimer" @click="removeItem(index)"></i>
            </td>
        </tr>
        <tr class="active center aligned">
            <td>
                <input type="text" v-model="current_item.annee" placeholder="Année" :disabled="isDisabled">
            </td>
            <td>
                <input type="number" min="0" v-model.number="current_item.valeur" placeholder="Valeur" :disabled="isDisabled">
            </td>
            <td :class="{'disabled': isDisabled}">
                <i class="plus green icon" style="cursor: pointer" title="ajouter l'element" @click="addItem"></i>
            </td>
        </tr>
        </tbody>

    </table>
</template>

<script>
    export default {
        name: "detail_valeur_neuf",
        props: {
            items:{type:Array, default(){ return [] }},
            isDisabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                working_items:this.items,
                current_item: {},
            }
        },
        methods: {
            removeItem(index){
                this.working_items.splice(index, 1)
            },
            addItem(){
                console.log('addItem');
                if(!_.isEmpty(this.current_item)){
                    // this.current_item.montant = this.total
                    this.working_items.push(JSON.parse(JSON.stringify(this.current_item)))
                    this.current_item={};
                }
            }
        }
    }
</script>

<style scoped>
    table th{
        line-height: 31px !important;
        padding-bottom: 0 !important;
    }
</style>