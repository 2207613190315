<template>
    <div class="ui fluid card tasksCard p-half" >
        <div class="content p-0">
            <div class="header tasksHeader" style="float: none;">
                Tâches réalisées
            </div>
            <div class="ui feed" style="margin-top:0.3em;max-height: 180px; overflow: auto; width: 100%">
                <div class="event" v-for="task in tasks_sorted" v-if="(task.message !== '' && task.action._id==='dispatcher') || task.action._id!=='dispatcher' || task.action.task_status === 'active'">
                    <div class="label small-avatar">
                        <avatar :user="task.receiver"/>
                    </div>
                    <div class="content my-0 small-text">
                       
                        <div class="summary">
                           <div>  
                            <span  v-if="task.date_validation">
                                {{ $store.getDate(task.date_validation, 'DD/MM/YYYY HH:mm') }}
                            </span>
                            <span v-else>
                                Encours
                            </span>
                            <span class="text-blue">{{task.action.name}}</span>
                            {{ task.action.task_status ==='active' ? 'Encours de realisation par :' : ' : ' }}
                            <span :style="task.action.task_status !=='active'? 'color: #000' : 'color: #0953AA'">{{task.receiver.username}}</span>.</div>
                            <p class="task-comments"> {{ task.message }} </p>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="description taskContainer">
                <div class="ui form">
                    <div class="ui read-only checkbox task" v-for="task in tasks_sorted">
                        <input type="checkbox" name="example" :checked="task.action.task_status==='complete'" :disabled="task.action.task_status === 'complete'">
                        <label class="taskLabel">{{task.action.name}}</label>
                    </div>
                </div>
            </div>-->

        </div>
    </div>
</template>

<script>
    export default {
        props:{
            dossier:{type:Object},
            tasks:{type: Array},
            isEdit:{type: Boolean},
        },
        computed:{
            current_user(){
                return this.$store.state.current_user;
            },
          tasks_sorted(){
              let creation_dossier = {action:{name:'Creation dossier', task_status: 'active'}, receiver: this.current_user, date_creation:0}
              if(this.isEdit && this.dossier){
                  creation_dossier.action.task_status =  'complete';
                  creation_dossier.date_validation = this.dossier.date_creation
                  creation_dossier.receiver = this.dossier.user
                  creation_dossier.message = this.dossier.message
              }
              let tasks_list = [creation_dossier].concat(this.tasks);
              return _.orderBy(tasks_list, 'date_creation', 'desc')
          }
        },
        /*updated() {
            $('.ui.checkbox').checkbox();
        },
        mounted() {
            $('.ui.checkbox').checkbox();
        }*/
    }
</script>

<style scoped>
    .task-comments{
        color: var(--main-color-green);
    }
</style>