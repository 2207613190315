<template>
    <div class="card actionsCard">
        <div class="ui pointing blue secondary menu" style="margin-bottom: 25px">
            <a class="item" :class="{'active': tab==='generation'}" @click="tab='generation'"><i class="add icon"></i>Nouveau</a>
            <a class="item" :class="{'active': tab==='history'}" @click="tab='history'"><i class="history icon"></i>Historique</a>
            <div class="right  menu">
                <a class="ui  item" style="color:red" @click="confirmationCloture" :disable="isBusy"><i class="window close icon"></i>Terminer</a>
            </div>
        </div>

        <template v-if="tab === 'generation'">
            <div class="fourteen wide column">
                <h3 style="margin-bottom: 1.5em">Options de generation</h3>
                <div class="ui form">

                    <div class="required field">
                        <label>Document</label>
                        <v-select :options="pdf_templates" v-model="selected_pdf_template" label="name"
                                  class="formInput" placeholder="Document" @input="changeOptionData">
                        </v-select>
                    </div>

                    <div class="required field"
                         v-if="selected_pdf_template.id === 'relance_reparation' || selected_pdf_template.id === 'relance_facture_achat'">
                        <label>Option</label>
                        <v-select :options="options" v-model="pdf_template_options.option" label="label"
                                  class="formInput" placeholder="Option" style="text-transform: capitalize">
                        </v-select>
                    </div>

                    <div class="field">
                        <label>Commentaire</label>
                        <textarea v-model="pdf_template_options.note" class="formInput" rows="3" placeholder="Commentaire"></textarea>
                    </div>
                </div>
            </div>

            <div class="button" style="margin-top:1.5em">
                <button class="ui right labeled icon big button danger-button" :class="{'loading':isBusy}"
                        :disabled="isBusy" @click="generateReport">
                    <i class="file pdf icon"></i>Générer Rapport
                </button>
            </div>
        </template>

        <template v-else-if="tab === 'history'">
            <generatedFilesTable :attachment_prop="attachment_prop" :filterBy="{type:'generated', task_template: template_name}"/>
        </template>

        <confirm-cloture @clotureConfirmed="completeTask" />

    </div>
</template>

<script>
    import generatedFilesTable from "@/components/document/generated_files_table.vue";
    import confirmCloture from "@/components/modals/confirm-cloture.vue";
    export default {
        name: "instruire_dossier",
        components:{
            generatedFilesTable,
            confirmCloture
        },
        props:{
            dossier:{type:Object},
            task:{type:Object},
            attachment_prop:{type:Array},
        },
        data() {
            return {
                isBusy:false,
                tab:'generation',
                pdf_template_options: {
                    id_doc: this.dossier._id,
                    note: '',
                    option: ''
                },
                template_name: 'instruire_dossier',
                selected_pdf_template: '',
                options: [],
                pdf_templates: [
                    {id: 'relance_devis', name: 'Relance devis'},
                    {id: 'devis_garage', name: 'Devis garage'},
                    {id: 'relance_document_base', name: 'Relance document de base'},
                    {id: 'relance_photo_avant', name: 'Relance photo avant'},
                    {id: 'relance_photo_apres', name: 'Relance photo après'},
                    {id: 'relance_reparation', name: 'Relance Réparation'},
                    {id: 'relance_facture_achat', name: 'Relance facture d’achat'},
	                //
	                {id: "med_devis", name: 'Mise en demeure devis'},
	                {id: "med_facture_achat_des_pieces", name: "Mise en demeure facture d’achat des pièces"},
	                {id: "med_facture", name: 'Mise en demeure facture'},
	                {id: "med_ph_apr_avec_pieces", name: 'Mise en demeure photos après avec pièces'},
			        {id: "med_ph_apr_et_facture", name: 'Mise en demeure photos après et facture'},
			        {id: "r_facture", name: 'Relance facture'},
                    //
                    {id: "attestation_reforme", name: 'Attestation de reforme'},
                    {id: "lettre_accord_irrevocable", name: 'Lettre d\'accord irrevocable'},
                    {id: "pec_reforme", name: 'Prise en charge'},
                    {id: "radiation", name: 'Radiation'},
                    {id: "att_expertise_apr_reparation", name: 'Attestation d’expertise après réparation'},
                    //
                    {id: "med_phav", name: 'Mise en demeure photos avant réparation'}
                ]
            }
        },
        methods:{
            confirmationCloture(){
                console.log('`confirmationMessage`')
                this.$modal.show("cloture-modal")
            },
            completeTask(message){
                console.log('completeTask');
                if(this.isBusy) return;

                this.isBusy=true;
                let query = {
                    task_to_complete: this.task._id,
                    receiver: this.dossier.dispatcher,
                    action: this.$store.getters.findOneAction({_id:'dispatcher'}),
                    message: message,
                    document: this.dossier,
                    car_location: null
                };
                this.$store.dispatchAsync(this.$SHARED.services.share, query).then(data => {
                    this.isBusy=false;
                    this.$notify({group: 'user-message', type: 'success',
                        text: 'rendez-vous valider avec succee'
                    });
                    this.$emit('task_completed')
                }, code => {
                    this.isBusy=false;
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                });
            },
            generateReport(){
                if(!this.selected_pdf_template){
                    this.$notify({group: 'user-message', type: 'error',
                        text: 'Sélectionnez un document !'
                    });
                    return;
                }
                if(this.selected_pdf_template.id === 'relance_reparation' || this.selected_pdf_template.id === 'relance_facture_achat'){
                    if(!this.pdf_template_options.option){
                        this.$notify({group: 'user-message', type: 'error',
                            text: 'Sélectionnez une option !'});
                        return;
                    }
                }
                console.log('generateReport');
                this.isBusy=true;
                let query={
                    id_doc: this.dossier._id,
                    pdf_template_name: this.selected_pdf_template.id,
                    pdf_template_options: this.pdf_template_options,
                    task_template: this.template_name
                };
                this.$store.dispatchAsync(this.$SHARED.services.generatePDF, query).then((data) => {
                    let link=this.$store.state.SERVER_ADDRESS + data.link;
                    console.log("link", link);
                    window.open(link);
                    this.$emit('refresh_attachments')
                    this.isBusy=false;
                }).catch(error=>{
                    this.isBusy= false;
                    this.$notify({group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            changeOptionData(){
                this.pdf_template_options.option = '';
                if(this.selected_pdf_template.id === 'relance_reparation'){
                    this.options = [ 'entamées', 'achevées' ];
                }
                else if(this.selected_pdf_template.id === 'relance_facture_achat'){
                    this.options = [
                        {object: 'du véhicule', label:'la facture d’achat du véhicule cité en objet'},
                        {object: 'des pièces d\'origine', label: 'les factures des pièces d\'origine'}
                    ]
                }
            }
        },

    }
</script>

<style scoped>
    .add-button{
        height: 48px;
        padding: 0 6px 0 15px !important;
        margin-top: 23px;
    }
    table th{
        line-height: 31px !important;
        padding-bottom: 0 !important;
    }
</style>