<template>
    <div class="card actionsCard">
        <div class="button">
            <button class="ui big button danger-button" :class="{'loading':isBusy}" :disabled="isBusy" @click="confirmationCloture">Cloturer</button>
        </div>
    </div>
</template>

<script>

    export default {
        name: "cloture",
        components:{},
        props:{
            dossier:{type:Object},
            task:{type:Object}
        },
        data() {
            return {
                isBusy:false,
            }
        },
        methods:{
            confirmationCloture(){
                console.log('`confirmationMessage`')
                this.$modal.show('dialog', {
                    title: 'Attention!',
                    text: 'Vous voulez vraiement cloturer ce dossier',
                    buttons: [
                        {
                            title: '<h4 style="color: #808080">Annuler</h4>',
                            default: true // gets clicked by default if you pushed enter
                        },
                        {
                            title: '<h4 style="color: red">Cloturer</h4>',
                            handler: () => {
                                this.cloture();
                                this.$modal.hide('dialog')
                            }
                        }
                    ]
                })
            },
            cloture(){
                console.log('cloture');
                if(this.isBusy) return

                this.isBusy=true;
                const data = {
                    collection: this.$SHARED.collections.documents,
                    query: {_id: {$in:[this.task._id, this.dossier._id] }},
                    update: {
                        '$set': {
                            status: 'cloturer',
                            'action.task_status': 'complete',
                            date_cloture: this.$moment().valueOf()
                        }
                    },
                    options: {'multi': true, 'upsert':false}
                };
                this.$store.requestUpdate(data).then(() => {
                    this.$emit('task_completed')
                    this.$notify({group: 'user-message', type: 'success',
                        text: 'dossier cloturée avec succee'
                    });
                    this.isBusy=false;
                }, code => {
                    this.isBusy=false;
                    this.$notify({group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                });
            }
        }

    }
</script>

<style scoped>

</style>