import { render, staticRenderFns } from "./pieces-joints.vue?vue&type=template&id=2e656eae&scoped=true"
import script from "./pieces-joints.vue?vue&type=script&lang=js"
export * from "./pieces-joints.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e656eae",
  null
  
)

export default component.exports