<template>
    <table class="ui very basic striped table">
        <thead>
        <tr>
            <th class="five wide">Désignation</th>
            <th class="three wide">Nbr heures</th>
            <th class="three wide">Taux horaire</th>
            <th class="five wide">Montant</th>
            <th class="one wide">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in working_items" >
            <td>{{item.designation}}</td>
            <td>{{item.nbr_heures}}</td>
            <td>{{item.taux_horaire}}</td>
            <td>{{item.montant ? item.montant.toFixed(2): ''}}</td>
            <td :class="{'disabled': isDisabled}" class="collapsing">
                <i class="pencil green icon" style="cursor: pointer" title="Editer" @click="editItem(index)"></i>
                <i class="x red icon" style="cursor: pointer" title="Supprimer" @click="removeItem(index)"></i>
            </td>
        </tr>
        <tr class="warning" v-show="working_items.length">
            <td> Total </td>
            <td></td>
            <td></td>
            <td> {{totalMontant}} </td>
            <td> </td>
        </tr>
        <tr class="active">
            <td>
                <!--<input type="text" v-model="current_item.designation" placeholder="désignation" :disabled="isDisabled">-->
                <v-select taggable :options="designation_list" :label="'label'" v-model="current_item.designation" :reduce="desig => desig.label"
                           placeholder="désignation" :disabled="isDisabled">
                </v-select>
            </td>

            <td>
                <input type="number" v-model.number="current_item.nbr_heures" placeholder="nombre heures" :disabled="isDisabled">
            </td>

            <td>
                <input type="number" v-model.number="current_item.taux_horaire" placeholder="taux horaire" :disabled="isDisabled">
            </td>

            <td>
                <input type="text" :value="total" placeholder="montant" disabled>
            </td>
            <td :class="{'disabled': isDisabled}" class="collapsing">
                <i v-if="editingItem" class="pencil blue icon" style="cursor: pointer" title="Editer" @click="saveEditItem"></i>
                <i v-else class="plus green icon" style="cursor: pointer" title="ajouter l'element" @click="addItem"></i>
            </td>
        </tr>
        </tbody>

    </table>
</template>

<script>
    export default {
        name: "detail_main_oeuvre",
        props: {
            items:{type:Array, default(){ return [] }},
            isDisabled: {
                type: Boolean,
                default: false
            },
            total_main_ouvre_prop: {
                type: Object
            }
        },
        data() {
            return {
                working_items:this.items,
                current_item: {},
                total_main_ouvre: this.total_main_ouvre_prop,
                designation_list: [],
                editingItem: false,
                editedIndex: null
            }
        },
        methods: {
            removeItem(index){
                this.$modal.show('dialog', {
                    title: 'Confirmation',
                    text: 'Voulez-vous supprimer cet élément?',
                    buttons: [
                        {
                            title: 'Non',
                            handler: () => this.$modal.hide('dialog')
                        },
                        {
                            title: 'Oui',
                            handler: () => {
                                this.working_items.splice(index, 1)
                                this.$modal.hide('dialog')
                            }
                        }
                    ]
                })
            },
            editItem(index){
                this.current_item = JSON.parse(JSON.stringify(this.working_items[index]))
                this.editingItem = true
                this.editedIndex = index
            },
            saveEditItem(){
                this.current_item.montant = this.total
                this.working_items[this.editedIndex] = JSON.parse(JSON.stringify(this.current_item))
                this.current_item = {}
                this.editingItem = false
                this.editedIndex = null
            },
            addItem(){
                console.log('addItem');
                if(!this.current_item.designation){
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: 'Désignation est obligatoire!'
                    });
                    return;
                }
                if(!_.isEmpty(this.current_item)){
                    this.current_item.montant = this.total
                    this.working_items.push(JSON.parse(JSON.stringify(this.current_item)))
                    this.current_item={};
                }
            },
            getDesignations() {
                log('getDesignations');
                const data = {
                    collection: this.$SHARED.collections.designation,
                    query: {
                        type: 'main_oeuvre'
                    },
                    options: {
                        page: 1, limit: -1, sort: {label: 1}
                    }
                };
                this.$store.requestFind(data).then(ar => {
                    this.designation_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            }
        },
        computed:{
            total(){
                if(this.current_item.nbr_heures && this.current_item.taux_horaire){
                    return this.current_item.nbr_heures * this.current_item.taux_horaire
                }
                return 0
            },
            totalMontant(){
                let totalMontant = 0;
                if(this.working_items){
                    this.working_items.forEach((item)=>{
                        totalMontant += item.montant
                    })
                    this.total_main_ouvre.total_montant = totalMontant
                }
                return totalMontant.toFixed(2);
            }
        },
        mounted() {
            this.getDesignations()
        }
    }
</script>

<style scoped>
    table th{
        line-height: 31px !important;
        padding-bottom: 0 !important;
    }
</style>