<template>
    <modal name="confirm-create-dossier" height="auto" class="no-overflow" width="520px" :pivotY="0.3" :clickToClose="false">
        <div style="height: 100%">
            <div class="ui attached left aligned segment">
                <h4 class="ui header" style="padding-bottom: 3px;color: #6b7c93;">Merci de choisire le dispatcher !</h4>

                <div class="ui form">
                    <div class="field">
                        <label style="color: #6b7c93;"> Dispatcher </label>
                        <UserGroupSelect class="formInput" v-model="selected_dispatcher" :filterOut="{type:'dispatcher'}" />
                    </div>
                </div>
            </div>
            <div class="ui two bottom attached buttons">
                <button class="ui red basic button" @click="$modal.hide('confirm-create-dossier')">Annuler</button>
                <button class="ui green basic white button" @click="$emit('creationConfirmed', selected_dispatcher)" :disabled="!selected_dispatcher">Crée dossier</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import UserGroupSelect from "@/components/UserGroupSelect.vue";
    export default {
        name: "confirm-create-dossier",
        components:{
            UserGroupSelect
        },
        data() {
            return {
                selected_dispatcher: null
            }
        }
    }
</script>

<style scoped>

</style>