<template>
    <div class="card actionsCard p-half">
        <div class="ui grid m-0">
            <div class="centered row p-0" style="text-align: left">
                <div class="five wide column">
                    <h4><i class="map outline icon"></i>Location</h4>
                </div>
                <div class="five wide right aligned column">
                    <a href="#" @click.prevent="$modal.show('map-search-modal')"><h4><i class="search icon"></i><u>Séléction manuelle</u></h4></a>
                </div>

            </div>
            <div class="centered row">
                <div id="map" class="map"></div>
            </div>
            <div class="centered row pt-0">
                <div class="ui active centered  loader" v-if="isBusy"></div>

                <div class="ui relaxed horizontal selection list">
                    <h5 class="blue-color m-0">Liste des elements les plus proche :</h5>
                    <h4 class="no-content-msg" style="font-size: 1.2rem !important;" v-if="!nearest.length">
                        <svg aria-hidden="true" width="80"  height="80" focusable="false" data-prefix="far" data-icon="map-marker-slash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-map-marker-slash fa-w-20 fa-2x">
                            <path fill="currentColor" d="M633.99 471.02L36 3.51C29.1-2.01 19.03-.9 13.51 6l-10 12.49C-2.02 25.39-.9 35.46 6 40.98l598 467.51c6.9 5.52 16.96 4.4 22.49-2.49l10-12.49c5.52-6.9 4.41-16.97-2.5-22.49zM320 48c79.4 0 144 64.6 144 144 0 25.07-2.93 41.62-18.88 70.43l38.23 29.89C505.9 253.46 512 228.95 512 192 512 86.4 425.6 0 320 0c-53.42 0-101.88 22.16-136.77 57.69l38.22 29.88C247.25 63.21 281.8 48 320 48zm39.41 342.09c-11.9 16.87-25 35.44-39.41 55.99-14.41-20.56-27.51-39.12-39.41-55.99-58.27-82.6-84.42-120.33-95.93-148.51l-56.53-44.2c1.27 72.49 29.05 98.96 172.67 305.02 4.8 6.4 12 9.6 19.2 9.6s14.4-3.2 19.2-9.6c24.46-35.1 45.29-64.59 63.48-90.38l-37.87-29.6c-1.84 2.61-3.5 4.97-5.4 7.67z" class=""></path>
                        </svg>
                        <br>
                        Séléctionner une location sur la map
                    </h4>

                    <div class="item" v-for="element in nearest" @click="selected_element=element" :class="{'selected-user' : selected_element === element }">
                        <div class="ui radio checkbox">
                            <input type="radio" name="user" checked="checked" :value="element" v-model="selected_element">
                            <label></label>
                        </div>
                        <avatar class="ui avatar image" :user="element" />

                        <div class="content">
                            <div class="header">{{element.firstname + ' '+ element.lastname}}</div>
                            <div class="blue-color">{{ !element.selected_manually ? unitToBest(element.distance) : 'Séléctionner mannuelement' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row centered pt-0">
                <div class="eight wide column">
                    <div class="ui form">
                        <div class="field m-0">
                            <label class="formLabel">
                                <h3 class="normal-text">Observations <span class="right floated add-icon-label">
                                        <svg viewBox="0 0 20 20" class="add-icon" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path
                                                        d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                                        fill="#0953AA" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <path fill="#fff" d="M0 0h20v20H0z" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Ajouter commentaire
                                    </span></h3>
                            </label>
                            <textarea v-model="message" class="formInput" id="" rows="3" placeholder="Observations / Commentaire"></textarea>
                        </div>
                        <div class="field flex" style="margin-top:0.3em">
                            <button class="ui button success-button ml-auto" @click="reserver" :class="{'loading':isBusy}" :disabled="!selected_element || isBusy">Réserver</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :clickToClose="true" name="map-search-modal" class="no-overflow"  height="auto" width="800px">
            <form class="ui form p-1" >
                <div class=" field">
                    <label>ASSIGNE A:</label>
                    <UserGroupSelect class="formInput" v-model="modal_selected_user" />
                </div>
                <div class="field">
                    <button class="ui button" @click.prevent="selectNotNear(modal_selected_user)">Confirmer</button>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
    import 'leaflet/dist/leaflet.css';
    import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
    import 'leaflet-defaulticon-compatibility';
    import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css'
    import 'leaflet-extra-markers'
    import 'leaflet-easybutton';
    import 'leaflet-easybutton/src/easy-button.css';
    import L from "leaflet";
    import UserGroupSelect from "@/components/UserGroupSelect.vue";
    export default {
        name: "prise_rdv",
        props:{
            dossier:{type:Object},
            task:{type:Object}
        },
        components: {
            UserGroupSelect
        },
        data() {
            return {
                isBusy:false,
                map: null,
                data:[],
                working_layer: null,
                entity_layer: null,
                shapes:{},
                nearest:[],
                selected_element:null,
                message:'',
                selected_position:null,
                redCarMarker:L.ExtraMarkers.icon({
                    icon: 'car',
                    markerColor: 'orange-dark',
                    shape: 'penta',
                    prefix: 'icon'
                }),
                modal_selected_user:null,
            };
        },
        methods: {
            reserver(){
                console.log('reserver');
                if(this.isBusy) return;

                this.isBusy=true;
                let query = {
                    task_to_complete: this.task._id,
                    receiver: this.selected_element,
                    action:this.$store.getters.findOneAction({_id:'valider_rdv'}),
                    message:this.message,
                    document: this.dossier
                };
                if(this.selected_position)
                    query.location = this.selected_position;

                this.$store.dispatchAsync(this.$SHARED.services.share, query).then(data => {
                    this.isBusy=false;
                    this.$notify({group: 'user-message', type: 'success',
                        text: 'demande de reservation envoyee avec succee'
                    });
                    this.$emit('task_completed')
                }, code => {
                    this.isBusy=false;
                    this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages[code]});
                });
            },
            setupMap(){
                this.map = L.map('map',{center:[33.9570,-6.8782], zoom:13});
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '',
                    maxZoom: 18,
                }).addTo(this.map);
                this.working_layer = new L.layerGroup;
                this.entity_layer = new L.layerGroup;
                this.map.addLayer(this.working_layer).addLayer(this.entity_layer);
                L.easyButton('<i class="refresh icon"></i>', ()=>{
                    console.log("refreshing");
                    this.getData();
                },'refresh', 'refresh_button',{position:'topright'}).addTo(this.map)
                this.initEvents()
            },
            renderMarkers(){
                log('renderMarkers');
                this.entity_layer.clearLayers();
                this.data.forEach(entity=>{
                    if(entity.location){
                        let options = {id:entity._id, type:'marker', user: entity};
                        this.shapes[entity._id] = L.marker(L.GeoJSON.coordsToLatLng(entity.location), options)
                            .on('click',(e)=>{this.clickedMarker(e.target)})
                            .bindTooltip(this.renderToolTip(entity),{sticky:true,direction:'top'})
                            .addTo(this.entity_layer);
                    }
                })
            },
            renderToolTip(entity){
                return `
                    <div><b>Type:</b> ${entity.type}</div>
                    <b>username:</b> ${entity.username}</div> <br>
                    <div>${entity.firstname} ${entity.lastname}<div>
                `
            },
            clickedMarker(e){
                console.log('clickedMarker', e);
                if(e.options.user){
                    let find_result = _.find(this.nearest, {_id: e.options.user._id});
                    if(find_result){
                        this.selected_element = find_result
                    }
                    else{
                        // selected a user not in nearest
                        this.selectNotNear(e.options.user)
                    }
                }
            },
            selectNotNear(user){
                this.$modal.hide('map-search-modal')
                user.selected_manually = true;
                this.nearest = [];
                this.nearest.push(user);
                this.selected_element = user
            },
            getData(){
                const data = {collection: 'user', query: {type:'mobile'}, options: {page: 1, limit: -1}};
                this.$store.requestFind(data).then(ar => {
                    this.data = ar.docs;
                    this.renderMarkers()
                }, code => {
                    this.$notify({group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            initEvents(){
                this.map.on('click', this.addMarker);
            },
            addMarker(e){
                console.log("addMarker");
                this.selected_element=null
                this.working_layer.clearLayers();
                let newMarker = new L.marker(e.latlng,{icon:this.redCarMarker}).addTo(this.working_layer);
                this.getNearest(newMarker.getLatLng());
            },
            getNearest(point_coords){
                this.isBusy=true;
                this.selected_position = [ point_coords.lng, point_coords.lat ];
                const data = {collection: 'user',
                    pipeline: [{
                        $geoNear: {
                            query:{type:'mobile'},
                            near: { type: "Point", coordinates: this.selected_position},
                            spherical: true,
                            distanceField: "distance"
                        }
                    }]
                };
                this.$store.requestAggregate(data).then(result => {
                    this.isBusy=false;
                    this.nearest = result;
                }, code => {
                    this.isBusy=false;
                    this.$notify({
                        group: 'user-message',
                        type: 'warn',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            unitToBest(distance){
                let result = this.$units(Math.ceil(distance)).from('m').toBest();
                return result.val + ' ' + result.unit;
            },
            convertCords(cords){
                if(Array.isArray(cords)){
                    return cords.reverse()
                } else {
                    return cords;
                }
            },
        },
        mounted() {
            this.setupMap()
            this.getData()
        },
        beforeDestroy() {
            if (this.map) {
                this.map.remove();
            }
        },
        watch:{
            selected_element(){
                if(this.selected_element.location){
                    let location = this.convertCords(JSON.parse(JSON.stringify(this.selected_element.location)));
                    this.map.flyTo(location, 14);
                }
            }
        }
    }
</script>

<style scoped>
    #map {
        height: 50vh;
        width: 90vw;
        border-radius: 16px
    }

    .selected-user {
        background: rgba(0, 0, 0, .10) !important;
        color: rgba(0, 0, 0, .8) !important;
    }

    .blue-color {
        color: var(--main-color-blue) !important;
    }

</style>