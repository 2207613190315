<template>
    <div class="card actionsCard p-half">
        <div class="ui grid container" style="width: 100%; padding:0 10px">
            <div class="centered row">
                <div class="ten wide column">
                    <h4><i class="map outline icon"></i> Location</h4>
                    <h4 class="no-content-msg m-1" v-if="!this.task.location">
                        <svg aria-hidden="true" width="100"  height="100" focusable="false" data-prefix="far" data-icon="map-marker-slash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-map-marker-slash fa-w-20 fa-2x">
                            <path fill="currentColor" d="M633.99 471.02L36 3.51C29.1-2.01 19.03-.9 13.51 6l-10 12.49C-2.02 25.39-.9 35.46 6 40.98l598 467.51c6.9 5.52 16.96 4.4 22.49-2.49l10-12.49c5.52-6.9 4.41-16.97-2.5-22.49zM320 48c79.4 0 144 64.6 144 144 0 25.07-2.93 41.62-18.88 70.43l38.23 29.89C505.9 253.46 512 228.95 512 192 512 86.4 425.6 0 320 0c-53.42 0-101.88 22.16-136.77 57.69l38.22 29.88C247.25 63.21 281.8 48 320 48zm39.41 342.09c-11.9 16.87-25 35.44-39.41 55.99-14.41-20.56-27.51-39.12-39.41-55.99-58.27-82.6-84.42-120.33-95.93-148.51l-56.53-44.2c1.27 72.49 29.05 98.96 172.67 305.02 4.8 6.4 12 9.6 19.2 9.6s14.4-3.2 19.2-9.6c24.46-35.1 45.29-64.59 63.48-90.38l-37.87-29.6c-1.84 2.61-3.5 4.97-5.4 7.67z" class=""></path>
                        </svg>
                        <br>
                        Location n'est pas disponible
                    </h4>
                    <div id="map" class="map"></div>
                </div>
                <div class="six  wide column" style="width: auto">
                    <h4><i class="phone icon"></i> Contacts</h4>
                    <folder-info :dossier="dossier" style="text-align: left"></folder-info>
                </div>

            </div>

            <div class="centered row" style="margin-top:25px">
                <div class="eight wide column">
                    <div class="ui form p-1">
                        <div class="field">
                            <label class="formLabel">
                                <h3>Observations <span class="right floated add-icon-label">
                                        <svg viewBox="0 0 20 20" class="add-icon" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path
                                                        d="M17.071 2.929A9.935 9.935 0 0010 0a9.935 9.935 0 00-7.071 2.929A9.935 9.935 0 000 10a9.935 9.935 0 002.929 7.071A9.935 9.935 0 0010 20a9.935 9.935 0 007.071-2.929A9.935 9.935 0 0020 10a9.935 9.935 0 00-2.929-7.071zM10 18.438c-4.652 0-8.438-3.786-8.438-8.438 0-4.652 3.786-8.438 8.438-8.438 4.652 0 8.438 3.786 8.438 8.438 0 4.652-3.786 8.438-8.438 8.438zm.781-9.22h3.516v1.563H10.78v3.515H9.22v-3.515H5.703V9.217H9.22V5.702h1.562v3.516z"
                                                        fill="#0953AA" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <path fill="#fff" d="M0 0h20v20H0z" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Ajouter commentaire
                                    </span></h3>
                            </label>
                            <textarea v-model="message" class="formInput" id="" rows="3" placeholder="Observations / Commentaire"></textarea>
                        </div>
                        <div class="button">
                            <button class="ui right floated button success-button" @click="valider" :class="{'loading':isBusy}" :disabled="isBusy">Terminer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'leaflet/dist/leaflet.css';
    import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
    import 'leaflet-defaulticon-compatibility';
    import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css'
    import 'leaflet-extra-markers'
    import FolderInfo from "@/components/folder-info.vue";
    import L from "leaflet";
    export default {
        name: "valider_rdv",
        components:{
            FolderInfo
        },
        props:{
            dossier:{type:Object},
            task:{type:Object}
        },
        data(){
            return {
                isBusy:false,
                map: null,
                working_layer:null,
                message:'',
            }
        },
        methods:{
            valider(){
                console.log('valider');
                if(this.isBusy) return;

                this.isBusy = true;
                let query = {
                    task_to_complete: this.task._id,
                    receiver: this.dossier.dispatcher,
                    action: this.$store.getters.findOneAction({_id:'dispatcher'}),
                    message:this.message,
                    document: this.dossier,
                    car_location: null
                };
                this.$store.dispatchAsync(this.$SHARED.services.share, query).then(data => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'success',
                        text: 'rendez-vous valider avec succee'
                    });
                    this.$emit('task_completed')
                }, code => {
                    this.isBusy = false;
                    this.$notify({group: 'user-message', type: 'error',
                        text: this.$SHARED.messages[code]
                    });
                });
            },
            setupMap(){
                this.map = L.map('map',{center:[33.9570,-6.8782], zoom:13});
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '',
                    maxZoom: 18,
                }).addTo(this.map);
                this.working_layer = new L.layerGroup;
                this.entity_layer = new L.layerGroup;
                this.map.addLayer(this.working_layer).addLayer(this.entity_layer);
                let redCarMarker = L.ExtraMarkers.icon({
                    icon: 'car',
                    markerColor: 'orange-dark',
                    shape: 'penta',
                    prefix: 'icon'
                });
                L.marker(this.car_location,{icon:redCarMarker}).addTo(this.working_layer);
                this.zoomToCords(this.car_location)
            },
            convertCords(cords){
                if(Array.isArray(cords)){
                    return cords.reverse()
                } else {
                    return cords;
                }
            },
            zoomToCords(cords){
                this.map.flyTo(cords, 14);
            }
        },
        mounted() {
            if(this.task.location){
                this.car_location = this.convertCords(JSON.parse(JSON.stringify(this.task.location)));
                this.setupMap();
            }
        },
    }
</script>

<style scoped>
    #map {
        height: 100%;
        width: 100%;
        border-radius: 16px;
    }
</style>