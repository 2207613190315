<template>
    <div>
        <div class="tabs">
            <div class="type" :class="{'active' : selected_attachment === 'REQ'}"
                 @click="changeAttachment('REQ')">
                REQ
            </div>
            <div class="type" :class="{'active' : selected_attachment === 'constat_amiable'}"
                 @click="changeAttachment('constat_amiable')">
                Doc de base <!--Constat amiable-->
            </div>
            <div class="type" :class="{'active' : selected_attachment === 'carte_grise'}"
                 @click="changeAttachment('carte_grise')">
                CG <!--Carte grise-->
            </div>
            <div class="type" :class="{'active' : selected_attachment === 'contrat'}"
                 @click="changeAttachment('contrat')">
                CT <!--Contrat-->
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'PHAV'}"
                 @click="changeAttachment('PHAV')">
                PHAV
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'devis'}"
                 @click="changeAttachment('devis')">
                Devis
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'ACC_DEV'}"
                 @click="changeAttachment('ACC_DEV')">
                ACC DEV
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'PHENC'}"
                 @click="changeAttachment('PHENC')">
                PHENC
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'PHAPR'}"
                 @click="changeAttachment('PHAPR')">
                PHAPR
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'PHADV'}"
                 @click="changeAttachment('PHADV')">
                PHADV
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'facture'}"
                 @click="changeAttachment('facture')">
                Facture
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'forfait'}"
                 @click="changeAttachment('forfait')">
                Forfait
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'FA'}"
                 @click="changeAttachment('FA')">
                FA
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'relances'}"
                 @click="changeAttachment('relances')">
                Relances
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'AV_DOM'}"
                 @click="changeAttachment('AV_DOM')">
                AV DOM
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'AV_REF'}"
                 @click="changeAttachment('AV_REF')">
                AV REF
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'RAP_PR_CTR'}"
                 @click="changeAttachment('RAP_PR_CTR')">
                RAP PR CTR
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'RAP'}"
                 @click="changeAttachment('RAP')">
                RAP
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'NH'}"
                 @click="changeAttachment('NH')">
                NH
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'ID'}"
                 @click="changeAttachment('ID')">
                ID
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'PEC'}"
                 @click="changeAttachment('PEC')">
                PEC
            </div>
            <div class="type" :class="{'active' : selected_attachment === 'FTX'}"
                 @click="changeAttachment('FTX')">
                FTX
            </div>

            <div class="type" :class="{'active' : selected_attachment === 'autre'}"
                 @click="changeAttachment('autre')">
                AUTRE
            </div>

        </div>

        <template v-if="currentUser.type === 'manager'">
            <div class="ui horizontal divider" style="color: #29B737"> Action </div>
            <!--définir l'image par défaut-->
            <v-select placeholder="Sélectionner une action" style="width: 70%" v-model="selectedAction"
                      :reduce="item => item._id"
                      :options="[
                          {_id: 'default_image', label: 'Définir l\'image par défaut'},
                          {_id: 'delete_image', label: 'Supprimer des images'}
                      ]"
            />
        </template>

        <image-tab :image_src="attachment_image" :selectedAction="selectedAction"
                   @updateCompleted="$emit('updateCompleted')"/>

        <!-- upload modal -->
        <modal :clickToClose="true" name="upload-modal" height="auto" width="800px">
            <div class="ui container p-1">
                <upload-modal @uploadClick="uploadFile"/>
            </div>
            <!-- dimmer -->
            <div v-show="isBusy" class="ui inverted active dimmer" style="z-index: 999;">
                <div class="content">
                    <h2 class="ui small icon header" style="color: black;">
                        <i class="small spinner loading icon"></i>
                        Veuillez attendre la fin du transfert du fichier
                    </h2>
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
    import ImageTab from "@/components/image-tab.vue";
    import uploadModal from "@/components/modals/upload-modal.vue";
    export default {
        name: "pieces-joints",
        components:{ImageTab, uploadModal},
        props:{
            dossier: {
                type: Object,
                default: ()=> { return {}}
            },
            attachment_prop: Array
        },
        data(){
            return {
                attachment_list: [],
                selected_attachment: 'constat_amiable',
                attachment_image: [],
                isBusy: false,
                selectedAction: 'default_image'
            }
        },
        methods:{
            changeAttachment(type){
                this.attachment_image = _.filter(this.attachment_list, {type});
                this.selected_attachment = type;
            },
            uploadFile(data){
                try {
                    this.isBusy = true;
                    let url =
                        this.$store.state.SERVER_ADDRESS +
                        this.$SHARED.services.document.upload;
                    let formData = data.form;
                    let file = data.file;
                    let document_id = this.dossier._id;
                    if (!file) {
                        this.isBusy = false;
                        return;
                    }
                    for(const f of file){
                        formData.append("document[]", f);
                    }
                    formData.append("document_id", document_id);
                    formData.append("type", data.type);
                    formData.append("numero", this.dossier.numero)
                    $.ajax({
                        url: url,
                        type: "POST",
                        data: formData,
                        xhrFields: {
                            withCredentials: true
                        },
                        async: true,
                        cache: false,
                        contentType: false,
                        enctype: "multipart/form-data",
                        processData: false
                    })
                        .done(resp => {
                            this.isBusy = false;
                            let type = "error";
                            let text = this.$SHARED.messages[resp.message];
                            if (resp.succeeded) {
                                type = "success";
                                text = this.$SHARED.messages._200;
                                this.$emit('updateCompleted');
                                this.$modal.hide('upload-modal');
                            }
                            this.$notify({
                                group: "user-message",
                                type,
                                title: "",
                                text: text
                            });
                        })
                        .fail(err => {
                            this.isBusy = false;
                            this.$notify({
                                group: "user-message",
                                type: "error",
                                title: "Alert",
                                text:
                                    "erreur d'importation veuillez vérifier votre fichier"
                            });
                        });
                } catch (e) {
                    console.error(e, e);
                }
            }
        },
        watch:{
            attachment_prop(){
                this.attachment_list = this.attachment_prop;
                this.changeAttachment(this.selected_attachment);
            }
        },
        computed: {
            currentUser(){
                return this.$store.state.current_user
            }
        }
    }
</script>

<style scoped>

</style>